import React from "react";
import "./templates.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import Star from "../../img/Star.js";
import axios from "axios";
import { useEffect, useState } from "react";
import registerFonts from "./Fonts";
import renderStars from "./RenderStars";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
// import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";


const Asu = ({ cvData, cvDesign, selectedCVMal, cvFonts, cvSize, profilePicture }) => {
  registerFonts();
  let globalColors = {};

  // if (cvDesign) {

    globalColors = {
      primary: cvDesign ? cvDesign.primaryColor : "#27384D",
      secondary: cvDesign ? cvDesign.secondaryColor : "#d6e4fe",
      accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
      text: "#333333",
      font: cvFonts || "Poppins",
      textSizeBody: cvSize || 8,

    // };
  }
  // [{"id": 1, "hex": "#FEEED6"}, {"id": 2, "hex": "#d6e4fe"}, {"id": 3, "hex": "#d2d3f7"}, {"id": 4, "hex": "#f2dedc"}, {"id": 5, "hex": "#d4f2dc"}]
  // Create styles for the PDF
  const styles = StyleSheet.create(
    {
    document: {
      width: "595px",
      height: "841px",
      backgroundColor: globalColors.secondary,
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      position: "relative",
      backgroundColor: globalColors.secondary,
    },
    sidebar: {
      width: "30%",
      padding: 15,
      margin: 20,
      border: "1px solid " + globalColors.primary,
      borderRadius: 20,
      color: globalColors.primary,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 4,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 3,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 10,
      color: globalColors.primary,
      textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 40,
      marginTop: 40,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 20,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 5,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 2,
    },
    link: {
      color: globalColors.primary,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody - 2,
      display: "inline",
    },
    groupItems: {
      display: "inline",
      flexDirection: "row",
      alignItems: "center",
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "60%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      backgroundColor: globalColors.primary,
    },
    divider2: {
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.secondary,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.accent,
      width: "100%",
      height: 180,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  }
);

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;



  const [cvMal, setCVMal] = useState(null);
  convertImageToImg();
  useEffect(() => {
    const selectedCVFromLocalStorage = localStorage.getItem('selectedCV');
    if (selectedCVFromLocalStorage) {
      setCVMal(JSON.parse(selectedCVFromLocalStorage));
    }
    // selectedCVMalDeets(selectedCVMal, setCVMal);
  }, []);
  // }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}

        <Canvas id="magic" style={styles.blob}></Canvas>

        <View style={{ ...styles.sidebar }}>
        <Image 
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src={profilePicture}
          />
          {/* <Image

            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            // src={profilePicture}
            src="https://user-monhelle3-bucket.s3.eu-north-1.amazonaws.com/1730747035396_1700323325885.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASBGQK6PGNAT25SM6%2F20241104%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241104T192401Z&X-Amz-Expires=7200&X-Amz-Signature=44032ac990696085d8300f335f5e74affad74764c95c7b5c30329655e1983ea4&X-Amz-SignedHeaders=host&x-id=GetObject"
            // , "https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")}
            // src="https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          /> */}
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>Kontaktinformasjon</Text>
          <Divider2 />
      
          {safeGet(cvData, "Personalia.date") && (
            <Text style={styles.textItem}>
              Født: {safeGet(cvData, "Personalia.date")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <Text style={styles.textItem}>
              Email: {safeGet(cvData, "Personalia.email")}
            </Text>
          )}

          {safeGet(cvData, "Personalia.phonenumber") && (
            <Text style={styles.textItem}>
              Telefon: {safeGet(cvData, "Personalia.phonenumber")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <Text style={styles.textItem}>
              Adresse: {safeGet(cvData, "Personalia.address")}
            </Text>
          )}

          <Text style={styles.sectionTitle}>Ferdigheter</Text>
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.navn")}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Interesser</Text>
          <Divider2 />
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
              </Text>
              {/* {renderStars(safeGet(interest, "formData.grad"), styles)} */}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Språk</Text>
          <Divider2 />
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
                  <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {`${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                cvData,
                "Personalia.lastName",
                ""
              )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "secondaryColor", "#000000"),
            }}
          >
            Arbeidserfaring
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text style={{
            ...styles.sectionTitleMain,
            color: safeGet(cvDesign, "primaryColor", "#000000"),
          }}>
            Utdanning
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text style={{
            ...styles.sectionTitleMain,
            color: safeGet(cvDesign, "primaryColor", "#000000"),
          }}>
            Militærtjeneste
          </Text>
          <Divider />
          {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(military, "formData.fra dato")} -{" "}
                {safeGet(military, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(military, "formData.militærtjeneste")} -{" "}
                <Text>{safeGet(military, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={styles.light}>
                {safeGet(military, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text style={{
            ...styles.sectionTitleMain,
            color: safeGet(cvDesign, "primaryColor", "#000000"),
          }}>
            Kurs
          </Text>
          <Divider />
          {safeGet(cvData, "Kurs", []).map((kurs, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(kurs, "formData.fra dato")} -{" "}
                {safeGet(kurs, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(kurs, "formData.kurs")} -{" "}
                <Text>{safeGet(kurs, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={styles.light}>
                {safeGet(kurs, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text style={{
            ...styles.sectionTitleMain,
            color: safeGet(cvDesign, "primaryColor", "#000000"),
          }}>
            Frivillighet
          </Text>
          <Divider />
          {safeGet(cvData, "Frivillighet", []).map((frivillighet, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(frivillighet, "formData.fra dato")} -{" "}
                {safeGet(frivillighet, "formData.til dato", "Nåværende")}{" "}
              </Text>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(frivillighet, "formData.frivillighet")} -{" "}
                <Text>{safeGet(frivillighet, "formData.sted")}</Text>{" "}
              </Text>
              <Text style={styles.light}>
                {safeGet(frivillighet, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text style={{
            ...styles.sectionTitleMain,
            color: safeGet(cvDesign, "primaryColor", "#000000"),
          }}>
            Referanser
          </Text>
          <Divider />
          {safeGet(cvData, "Referanser", []).map((ref, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{
                ...styles.bold,
                ...styles.text,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}>
                {safeGet(ref, "formData.navn")} - {safeGet(ref, "formData.arbeidssted")}
              </Text>
              <Text style={styles.text}>Telefon: {safeGet(ref, "formData.telefon")}</Text>
              <Text style={styles.text}>Epost: {safeGet(ref, "formData.epost")}</Text>
            </View>
          ))}

          <Text style={styles.sectionTitleMain}>Portefølje</Text>
          <Divider2 />
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Link style={styles.link} src="www.instagram.com">
                Instagram
              </Link>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Link style={styles.link} src="www.twitter.com">
                Twitter
              </Link>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Link style={styles.link} src="www.github.com">
                Github
              </Link>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Link style={styles.link} src="www.linkedin.com">
                Linkedin
              </Link>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Link style={styles.link} src="www.exuma.no">
                Nettside
              </Link>
            )}
            
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Asu;
