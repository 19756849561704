import React, { useState } from "react";
import "../css/RichTextStyling.css";
import axios from "axios";
import { ReactComponent as AIIcon } from "../img/ai-icon.svg";
import { useLocalStorage } from "../hooks/useLocalStorage";
const RichTextEditor = ({ onChange, content, setContent, container }) => {
  const [modalContent, setModalContent] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [storedContent, setStoredContent] = useLocalStorage(container, "");
  const handleModal = () => {
    setModalContent("");
    setIsGenerating(true);

    document.getElementById(`myModal${container}`).style.display = "block";


    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/generate-text`,
        { content: content },
        { withCredentials: true }
      )
      .then((response) => {
        setModalContent(response.data.content);

        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        setModalContent("An error occurred while generating text.");
      });
  };

  const closeModal = () => {
    document.getElementById(`myModal${container}`).style.display = "none";
  };

  const handleAIIconClick = () => {
    handleModal();
  };

  const handleAcceptModalContent = () => {
    const lastUpdated = new Date().toISOString();
    let newData;
    
    if (container === "Utdanning" || container === "Arbeidserfaring") {
      newData = { "oppsummering": modalContent, lastUpdated };
      // localStorage.setItem(container, JSON.stringify(newData));
      setStoredContent(newData);
      setContent({ "oppsummering": modalContent });
    } else {
      newData = { "kort oppsummering": modalContent, lastUpdated };
      // localStorage.setItem('kort oppsummering', JSON.stringify(newData));
      setStoredContent(newData);
      setContent({ "kort oppsummering": modalContent });
    }
    
    // Call the parent's onChange handler to ensure consistency
    onChange(modalContent);
    closeModal();
  };

  return (
    <div className="rich-text-editor">
      <div className="toolbar">
        <button className="toolbar-button">
          <strong>B</strong>
        </button>
        <button className="toolbar-button">
          <em>I</em>
        </button>
        <button className="toolbar-button ai-icon">
          <AIIcon onClick={handleAIIconClick} />
        </button>
      </div>
      <textarea
        className="text-area"
        placeholder="kort oppsummering"
        value={content}
        onChange={(e) => onChange(e.target.value)}
      ></textarea>





        <div id={`myModal${container}`} className="modal">
          <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          {isGenerating ? (
            <p>Genererer...</p>
          ) : (
            <>
              <p>Generert av AI:</p>
              <textarea
                className="modal-textarea"
                value={modalContent}
                onChange={(e) => setModalContent(e.target.value)}
              ></textarea>
              <button
                className="accept-button"
                onClick={handleAcceptModalContent}
              >
                Aksepter
              </button>
              <button className="regenerate-button" onClick={handleModal}>
                Regenerer
              </button>
            </>
          )}
        </div>
      </div> 

    </div>
  );
};

export default RichTextEditor;
