import { useState, useEffect } from 'react';

export const useLocalStorage = (key, initialValue) => {
  // Get stored value or use initial value
  const [value, setValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // Update localStorage and trigger re-render
  const setStorageValue = (newValue) => {
    try {
      // Save state
      setValue(newValue);
      // Save to localStorage
      localStorage.setItem(key, JSON.stringify(newValue));
      // Dispatch custom event
      window.dispatchEvent(new Event('localStorageChange'));
    } catch (error) {
      console.error(`Error saving ${key} to localStorage:`, error);
    }
  };

  return [value, setStorageValue];
}; 