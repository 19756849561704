import { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../img/arrowRight.svg";
import { ReactComponent as CheckmarkIcon } from "../img/checkmark.svg";
import "../css/Design.css";
import axios from "axios";
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useAuth } from './AuthContext';

export default function TemplateSlider({templates}) {
// export default function TemplateSlider({templates, selectedTemplate, setSelectedTemplate}) {
    const [currentTemplate, setCurrentTemplate] = useState(0);
    const [activateRight, setActivateRight] = useState(true);
    const [activateLeft, setActivateLeft] = useState(true);
    const [storedData, setStoredData] = useLocalStorage('selectedCV', {});
    const shiftTemplatesLeft = () => {
        setCurrentTemplate((prev) => (prev - 1 + templates.length) % templates.length);
    }; 
    const { selectedTemplate, setSelectedTemplate } = useAuth();


    const shiftTemplatesRight = () => {
        setCurrentTemplate((prev) => (prev + 1) % templates.length);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
                shiftTemplatesLeft();
            } else if (event.key === "ArrowRight") {
                shiftTemplatesRight();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [templates.length]);
    const handleTemplateSelect = async (template) => {
        // First update the local state and localStorage
        const updatedTemplate = {
            ...template,
            lastUpdated: new Date().toISOString()
        };

        // Update both states synchronously
        setSelectedTemplate(updatedTemplate);
        setStoredData(updatedTemplate);

        // Force immediate re-render of the CV preview
        window.dispatchEvent(new Event('localStorageChange'));

        try {
            // Save template choice to server
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/chooseCvTemplate`, 
                {
                    cvTemplate: template,
                    cvId: localStorage.getItem('cvId')
                },
                { withCredentials: true }
            );

            if (response.data.cvId) {
                setStoredData({cvId: response.data.cvId});
                // localStorage.setItem('cvId', response.data.cvId);
            }
        } catch (error) {
            console.error('Error saving template choice:', error);
        }
    };


    return (
        <div className="cv-template-slider" tabIndex="0">
            <h2>Velg CV</h2>
            <div className="template-slide-cv">
                <button className="slider-arrow left" onClick={shiftTemplatesLeft}>
                    <ArrowLeft />
                </button>
                <div className="template-container-cv">
                    {templates.map((template, index) => {
                        return (
                        <div
                            onClick={() => handleTemplateSelect(template)}
                            key={index}
                            className="template"
                            style={{ display: index === currentTemplate ? 'block' : 'none' }}
                        >
                            <img className="cvTemplateImages" src={process.env.REACT_APP_FRONTEND_URL+`${template.previewImage}`} alt={template.name}></img>
                            {selectedTemplate.idcvMal === template.idcvMal && <CheckmarkIcon className="checkmarkTemplate"/>}
                            <p>{template.name}</p>
                        </div>
                        )
                    })}
                </div>
                <button className="slider-arrow right" onClick={shiftTemplatesRight}>
                    <ArrowRight />
                </button>
            </div>
        </div>
    )
}   