import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  pdf,
} from "@react-pdf/renderer";
import MyDocument from "../pages/templates/CVCreaterTest";
import CTA2 from "./buttons/CTA2";
import axios from "axios";
import Mainla from "../pages/templates/Mainla";
import Toyko from "../pages/templates/Toyko";
import Cali from "../pages/templates/Cali";
import Stokkholm from "../pages/templates/Stokkholm";
import Asu from "../pages/templates/Asu";

import registerFonts from "../pages/templates/Fonts";
// Import your CVPDF component and other necessary libraries

const CVPDF = ({ cvData }) => {
  // Your existing CVPDF component code remains the same...
};

// Component for rendering the download link
const DownloadablePDF = ({ cvData, cvDesign, selectedTemplate, cvFonts, cvSize }) => {
  const renderTemplate = () => {

    switch (selectedTemplate) {
      case "Toyko":
        return <Toyko cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
      case "Mainla":
        return <Mainla cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
      case "Cali":
        return <Cali cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
      case "Stokkholm":
        return <Stokkholm cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
      case "Asu":
        return <Asu cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
      default:
        return <Toyko cvData={cvData} cvDesign={cvDesign} selectedCVMal={selectedTemplate} cvFonts={cvFonts} cvSize={cvSize} />;
    }
  };

  return (
    <div className="downloadablePDF">
      <PDFDownloadLink
        document={renderTemplate()}
        // fileName={`cv.pdf`}
        fileName={cvData ? `${cvData.Personalia.firstName}${cvData.Personalia.lastName}.pdf` : "cv.pdf"}

      >
        {({ loading }) => (loading ? "Laster inn dokument..." : "Last ned CV")}
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadablePDF;
