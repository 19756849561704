import React from 'react';
import { Route, Redirect, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
// const ProtectedRoute = ({ children, ...rest }) => {
  const { isAuthenticated, userInfo } = useAuth();

  if(isAuthenticated !== undefined && !isAuthenticated) { 
    window.location.replace("/login");
    return null;
  }


  // return <>{children}</>
  return React.cloneElement(children, { userInfo });
};

export default ProtectedRoute;
