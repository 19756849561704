import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Agreements.css';

export default function Agreements() {
  const [agreements, setAgreements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAgreements = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agreements`);
        setAgreements(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch agreements. Please try again later.');
        setLoading(false);
      }
    };

    fetchAgreements();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  const formatCurrency = (amount) => {
    return (amount / 100).toFixed(2);
  };

  if (loading) return <div className="agreements-container"><h2>Loading...</h2></div>;
  if (error) return <div className="agreements-container"><h2>{error}</h2></div>;

  return (
    <div className="agreements-container">
      <h1>Vipps Payment Agreements</h1>
      {agreements.length === 0 ? (
        <p>No agreements found.</p>
      ) : (
        <ul className="agreements-list">
          {agreements.map((agreement) => (
            <li key={agreement.id} className="agreement-item">
              <h2>{agreement.productName}</h2>
              <div className="agreement-details">
                <span>ID: {agreement.id}</span>
                {/* <span>Status: {agreement.status}</span> */}
                <span data-status={agreement.status.toLowerCase()}>Status: {agreement.status}</span>
              </div>
              <div className="agreement-details">
                <span>Created: {formatDate(agreement.created)}</span>
                <span>Start: {formatDate(agreement.start)}</span>
              </div>
              <div className="agreement-pricing">
                <h3>Pricing</h3>
                <p>{formatCurrency(agreement.pricing.amount)} {agreement.pricing.currency}</p>
                <p>Type: {agreement.pricing.type}</p>
              </div>
              <div className="agreement-interval">
                <h3>Interval</h3>
                <p>{agreement.interval.text}</p>
              </div>
              {agreement.campaign && (
                <div className="agreement-campaign">
                  <h3>Campaign</h3>
                  <p>Price: {formatCurrency(agreement.campaign.price)} {agreement.pricing.currency}</p>
                  <p>Period: {agreement.campaign.period.text}</p>
                  <p>End: {formatDate(agreement.campaign.end)}</p>
                  <p>{agreement.campaign.explanation}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
