// Create styles
// Register custom fonts if needed
import React from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Canvas,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
// import Star from '../img/StarFilled.svg';
// import Star from './Star.js';
import Star from "../../img/Star.js";
import axios from "axios";
import registerFonts from "./Fonts";
import { useEffect, useState } from "react";
import renderStars from "./RenderStars";
import profilePicPlaceholder from "../../img/profilePicPlaceholder.png";
import StarIcon from "./StarIcon.js";
import safeGet from "./SafeGet.js";
import selectedCVMalDeets from "./SelectedMalDeets.js";
import convertImageToImg from "./convertToImage.js";

const Toyko = ({
  cvData,
  cvDesign,
  selectedCVMal,
  cvFonts,
  cvSize,
  profilePicture,
}) => {
  registerFonts();
  convertImageToImg();

  let globalColors = {};

  // if (cvDesign) {
  globalColors = {
    primary: cvDesign ? cvDesign.primaryColor : "#27384D",
    secondary: "#ffffff",
    accent: cvDesign ? cvDesign.tertiaryColor : globalColors.secondary,
    text: "#333333",
    font: cvFonts || "Inter",
    textSizeBody: cvSize || 10,
  };
  // }

  // Create styles for the PDF
  const styles = StyleSheet.create({
    document: {
      width: "60%",
      height: "100%",
      minHeight: "800px",
      backgroundColor: globalColors.secondary,
      overflow: "hidden",
    },
    page: {
      display: "flex",
      flexDirection: "row",
      fontSize: globalColors.textSizeBody,
      height: "100%",
      // backgroundColor: globalColors.secondary,
      position: "relative",
    },
    sidebar: {
      width: "40%",
      padding: 15,
      marginLeft: 30,
      backgroundColor: globalColors.primary,
      color: globalColors.secondary,
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 2,
    },
    profilePicture: {
      display: "flex",
      width: "120px",
      height: "120px",
      objectFit: "cover",
      borderRadius: "50%",
      borderColor: globalColors.primary,
      borderWidth: 2,
      marginBottom: 10,
      backgroundColor: globalColors.secondary,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      zIndex: 999,
    },
    stars: {
      display: "flex",
      width: "10px",
      height: "10px",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    mainContent: {
      width: "70%",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop: "30px",
      position: "relative",
    },
    sectionTitle: {
      fontSize: globalColors.textSizeBody,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 0,
      marginTop: 5,
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 10,
      paddingRight: 10,
      color: globalColors.secondary,
      textTransform: "uppercase",
    },
    firstNameSection: {
      marginBottom: 20,
      marginTop: 20,
      fontSize: globalColors.textSizeBody + 10,
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    firstName: {
      fontSize: globalColors.textSizeBody + 20,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      display: "block",
    },
    sectionTitleMain: {
      fontSize: globalColors.textSizeBody + 4,
      fontFamily: globalColors.font,
      fontWeight: "bold",
      marginBottom: 10,
      marginTop: 10,
    },
    starContainer: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    star: {
      width: 10,
      height: 10,
      marginRight: 3,
    },
    textItem: {
      marginBottom: 3,
      fontSize: globalColors.textSizeBody - 2,
    },
    link: {
      color: globalColors.primary,
      textDecoration: "underline",
      fontSize: globalColors.textSizeBody - 2,
    },
    groupItems: {
      display: "inline",
      flexDirection: "row",
      alignItems: "center",
    },
    groupItemsSoc: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "80%",
    },
    columnGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: "10px",
    },
    divider: {
      height: 1.7,
      backgroundColor: globalColors.primary,
      marginBottom: "10px",
    },
    divider2: {
      marginLeft: "0px",
      height: 1.7,
      backgroundColor: globalColors.secondary,
      marginBottom: "10px",
    },
    bold: {
      fontFamily: globalColors.font,
      fontWeight: "bold",
    },
    text: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
    },
    light: {
      fontSize: globalColors.textSizeBody,
      marginBottom: 2,
      marginTop: 2,
      fontWeight: "normal",
      fontFamily: globalColors.font,
    },
    blob: {
      backgroundColor: globalColors.tertiary,
      width: "110%",
      height: 150,
      position: "absolute",
      top: 40,
      left: -50,
      zIndex: 6,
    },
    blob2: {
      backgroundColor: globalColors.primary,
      width: 150,
      height: "100%",
      position: "absolute",
      top: 0,
      left: -150,
      zIndex: 20,
    },
    blob3: {
      backgroundColor: globalColors.accent,
      width: 120,
      height: 120,
      borderRadius: 100,
      objectPositionX: "-10px",
      objectPositionY: "-10px",
      position: "absolute",
      bottom: -30,
      right: -30,
    },
  });

  const Divider = () => <View style={styles.divider} />;
  const Divider2 = () => <View style={styles.divider2} />;

  const [cvMal, setCVMal] = useState(null);

  useEffect(() => {
    selectedCVMalDeets(selectedCVMal, setCVMal);
  }, [selectedCVMal]);

  if (!cvData) return null;

  return (
    <Document
      size="A4"
      orientation="portrait"
      className="document"
      style={styles.document}
    >
      <Page style={styles.page}>
        {/* Sidebar for personal details */}
        <Canvas id="magic" style={styles.blob}></Canvas>
        <View style={{ ...styles.sidebar }}>
          {/* Problem here */}
          <Image
            style={styles.profilePicture}
            // src={profilePicPlaceholder}
            src={profilePicture}
          />
          <Text style={styles.light}>
            {safeGet(cvData, "kort oppsummering")}
          </Text>
          <Text style={styles.sectionTitle}>Kontaktinformasjon</Text>
          <Divider2 />

          {safeGet(cvData, "Personalia.date") && (
            <Text style={styles.textItem}>
              Født: {safeGet(cvData, "Personalia.date")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.email") && (
            <Text style={styles.textItem}>
              Email: {safeGet(cvData, "Personalia.email")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.phonenumber") && (
            <Text style={styles.textItem}>
              Telefon: {safeGet(cvData, "Personalia.phonenumber")}
            </Text>
          )}
          {safeGet(cvData, "Personalia.address") && (
            <Text style={styles.textItem}>
              Adresse: {safeGet(cvData, "Personalia.address")}
            </Text>
          )}

          <Text style={styles.sectionTitle}>Ferdigheter</Text>
          <Divider2 />
          {safeGet(cvData, "Ferdigheter", []).map((skill, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(skill, "formData.navn")}
              </Text>

              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Interesser</Text>
          <Divider2 />
          {safeGet(cvData, "Interesser", []).map((interest, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.interesser")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(interest, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(skill, "formData.grad"), styles)} */}
            </View>
          ))}

          <Text style={styles.sectionTitle}>Språk</Text>
          <Divider2 />
          {safeGet(cvData, "Språk", []).map((language, index) => (
            <View key={index} style={styles.groupItems}>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.navn")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(language, "formData.språk")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
          <Text style={styles.sectionTitle}>Kurs</Text>
          <Divider2 />
          {safeGet(cvData, "Kurs", []).map((course, index) => (
            <View key={index}>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.kurs")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.sted")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* <Text style={styles.text}>
              </Text>    */}
              <Text style={styles.text}>
                {safeGet(course, "formData.fra dato")} -{" "}
                {safeGet(course, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(course, "formData.oppsummering")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
          <Text style={styles.sectionTitle}>Frivillighet</Text>
          <Divider2 />
          {safeGet(cvData, "Frivillighet", []).map((volunteer, index) => (
            <View key={index}>
              <Text style={styles.textItem}>
                {safeGet(volunteer, "formData.frivillighet")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(volunteer, "formData.sted")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* <Text style={styles.text}>
              </Text>    */}
              <Text style={styles.text}>
                {safeGet(volunteer, "formData.fra dato")} -{" "}
                {safeGet(volunteer, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(volunteer, "formData.oppsummering")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
          <Text style={styles.sectionTitle}>Militærtjeneste </Text>
          <Divider2 />
          {safeGet(cvData, "Militærtjeneste", []).map((military, index) => (
            <View key={index}>
              <Text style={styles.textItem}>
                {safeGet(military, "formData.militærtjeneste")}
                {/* {safeGet(language, "formData.navn")} - {safeGet(language, "formData.grad")} */}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(military, "formData.sted")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* <Text style={styles.text}>
              </Text>    */}
              <Text style={styles.text}>
                {safeGet(military, "formData.fra dato")} -{" "}
                {safeGet(military, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.textItem}>
                {safeGet(military, "formData.oppsummering")}
                {/* {safeGet(language, "formData.språk")} - {safeGet(language, "formData.grad")} */}
              </Text>
              {/* {renderStars(safeGet(language, "formData.grad"), styles)} */}
            </View>
          ))}
        </View>

        {/* Main content */}
        <View style={styles.mainContent}>
          {/* <Canvas style={styles.blob2}></Canvas> */}
          {/* <Canvas style={styles.blob3}></Canvas> */}
          <View style={styles.firstNameSection}>
            {" "}
            <Text
              style={{
                ...styles.firstName,
                color: safeGet(cvDesign, "primaryColor", "#000000"),
              }}
            >
              {`${safeGet(cvData, "Personalia.firstName", "")} ${safeGet(
                cvData,
                "Personalia.lastName",
                ""
              )}`}
            </Text>
            <Text style={{ ...styles.bold, ...styles.text }}>
              {safeGet(cvData, "Personalia.jobtitle")}
            </Text>
          </View>

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            Arbeidserfaring
          </Text>
          <Divider />
          {safeGet(cvData, "Arbeidserfaring", []).map((job, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={styles.text}>
                {safeGet(job, "formData.fra dato")} -{" "}
                {safeGet(job, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.bold}>
                {safeGet(job, "formData.stillingstittel")} |{" "}
                {safeGet(job, "formData.arbeidssted")}
              </Text>
              <Text style={styles.light}>
                {safeGet(job, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            Utdanning
          </Text>
          <Divider />
          {safeGet(cvData, "Utdanning", []).map((education, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text style={{ ...styles.bold, ...styles.text }}>
                {safeGet(education, "formData.utdanningsnavn")} -{" "}
                <Text>{safeGet(education, "formData.utdanningssted")}</Text>
              </Text>
              <Text style={styles.text}>
                {safeGet(education, "formData.fra dato")} -{" "}
                {safeGet(education, "formData.til dato", "Nåværende")}
              </Text>
              <Text style={styles.light}>
                {safeGet(education, "formData.oppsummering")}
              </Text>
            </View>
          ))}

          <Text
            style={{
              ...styles.sectionTitleMain,
              color: safeGet(cvDesign, "primaryColor", "#000000"),
            }}
          >
            Referanser
          </Text>
          <Divider />
          {safeGet(cvData, "Referanser", []).map((ref, index) => (
            <View key={index} style={styles.columnGroup}>
              <Text
                style={{
                  ...styles.bold,
                  ...styles.text,
                  color: safeGet(cvDesign, "primaryColor", "#000000"),
                }}
              >
                {safeGet(ref, "formData.navn")} -{" "}
                {safeGet(ref, "formData.arbeidssted")}
              </Text>
              <Text style={styles.text}>
                Telefon: {safeGet(ref, "formData.telefon")}
              </Text>
              <Text style={styles.text}>
                Epost: {safeGet(ref, "formData.epost")}
              </Text>
            </View>
          ))}

          <Text style={styles.sectionTitleMain}>Portefølje</Text>
          <Divider2 />
          <View style={styles.groupItemsSoc}>
            {safeGet(cvData, "Portefølje.instagram") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.instagram.com">
                  Instagram
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.twitter") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.twitter.com">
                  Twitter
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.github") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.github.com">
                  Github
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.linkedin") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.linkedin.com">
                  Linkedin
                </Link>
              </Text>
            )}
            {safeGet(cvData, "Portefølje.nettside") && (
              <Text style={styles.link}>
                <Link style={styles.link} src="www.exuma.no">
                  Nettside
                </Link>
              </Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Toyko;
