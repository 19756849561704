import RichTextEditor from "./RichTextEditor";
import { useEffect, useState } from "react";
import saveData from "./saveData";
import updateCV from "./updateCV";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
export default function ShortDesc() {

  const [serverLastUpdated, setServerLastUpdated] = useState(null);
  const { isAuthenticated } = useAuth();
  const [shortContent, setShortContent] = useLocalStorage("kort oppsummering", {});
  const [content, setContent] = useState(() => {
    const savedData = localStorage.getItem('kort oppsummering');
    return savedData ? JSON.parse(savedData) : { "kort oppsummering": "" };
  });

  const handleMouseLeave = () => {
    let idcv = window.location.search.split("=")[1];

    if (idcv) {
      updateCV(content, idcv);
    } else {
      saveData(content);
    }
  };

  useEffect(() => {
    console.log(isAuthenticated)
    if (isAuthenticated) {
      console.log("fetching shortDesc");
      if(!localStorage.getItem('kort oppsummering')) {
        
        fetchShortDesc();
      }

        // Set up interval to check for updates
        const interval = setInterval(() => {
            const localData = JSON.parse(localStorage.getItem('kort oppsummering'));
            const localLastUpdated = localData?.lastUpdated;
            
            if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                syncWithServer(localData);
            }
        }, 10000); // Check every 10 seconds

        return () => clearInterval(interval);
    }
}, [isAuthenticated, serverLastUpdated]);

const syncWithServer = async (data) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/cv/shortDesc`,
            { ...data, cvId: localStorage.getItem('cvId') },
            { withCredentials: true }
        );
        if(response.status === 200) {
            setServerLastUpdated(response.data.lastUpdated);
        }
    } catch (error) {
        console.error('Error syncing with server:', error);
    }
};

const fetchShortDesc = async () => {
    let token = window.location.search.split('=')[0];
    let idcv = window.location.search.split('=')[1];
    let cvId = localStorage.getItem('cvId');
  console.log(cvId, idcv, token)
    //if localstorage and browser has id, and they are the same, fetch latest data from server
    if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {
        console.log("fetching latest data from server, it exists both in localstorage and browser");
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveShortDesc`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {

            const localData = JSON.parse(localStorage.getItem('kort oppsummering'));
            if (!localData) {
              setShortContent(response.data);
                // localStorage.setItem('kort oppsummering', JSON.stringify(response.data));
            }

            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if (!idcv && cvId) {
        console.log("fetching latest data from server, it exists only in localstorage");
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveShortDesc`, {
            withCredentials: true,
            params: {cvId}
        }).then((response) => {
            const localData = JSON.parse(localStorage.getItem('kort oppsummering'));
            if (!localData) {
              setShortContent(response.data);
                // localStorage.setItem('kort oppsummering', JSON.stringify(response.data));

            }
            setServerLastUpdated(response.data.lastUpdated);
        });
    } else if ((idcv && token === "?idcv") && cvId !== idcv) {
        console.log("fetching latest data from server, browser shows a different id than localstorage, need to update localstorage");
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveShortDesc`, {
            withCredentials: true,
            params: {cvId: idcv}
        }).then((response) => {
          setShortContent(response.data);
                // localStorage.setItem('kort oppsummering', JSON.stringify(response.data));

            setServerLastUpdated(response.data.lastUpdated);
        });
    }
}
/*
  const fetchLatestCVData = async () => {
    let token = window.location.search.split("=")[0];
    let idcv = window.location.search.split("=")[1];

    if (idcv && token === "?idcv") {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/fetch-cv-id`,
          { idcv: idcv },
          {
            withCredentials: true,
          }
        )
        .then((response) => {});
    } else {
      try {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/fetch-cv`,
            {},
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            if (
              response.data.cvContent &&
              response.data.cvContent["kort oppsummering"]
            ) {
              setContent({
                "kort oppsummering":
                  response.data.cvContent["kort oppsummering"],
              });
            }
          });
      } catch (error) {
        console.error("Error fetching CV data:", error);
      }
    }
  };
*/
  const handleInputChange = (value) => {
    const lastUpdated = new Date().toISOString();
    const newData = { "kort oppsummering": value, lastUpdated };

    // localStorage.setItem('kort oppsummering', JSON.stringify(newData));
    setShortContent(newData);
    setContent(newData);
  };

  return (
    <div className="personaliaBox">
    {/* <div className="personaliaBox" onMouseLeave={handleMouseLeave}> */}
      <div className="personaliaBoxPad">
        <h2>KORT OPPSUMMERING</h2>
        <p>Skriv inn en kort oppsummering av din erfaring</p>
        <RichTextEditor
          onChange={(value) => handleInputChange(value)}
          content={content["kort oppsummering"]}
          setContent={setContent}
          container="shortDesc"
        ></RichTextEditor>
      </div>
    </div>
  );
}
