import React, { useRef, useState, useEffect } from 'react';
import { pdf } from "@react-pdf/renderer";
import * as pdfjsLib from "pdfjs-dist";
import { ReactComponent as ArrowLeft } from "../../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../img/arrowRight.svg";

const PdfViewer2 = ({ template, profilePicture, cvDesign, cvFonts, cvSize, selectedTemplate }) => {
    const canvasRef = useRef(null);
    const [pdfData, setPdfData] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isRendering, setIsRendering] = useState(false);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const generatePdfBlob = async () => {
        try {
          if (!template) {
            console.error('Template is null or undefined');
            return;
          }

          // // Wait for image to load before generating PDF
          // if (template.props.profilePicture) {
          //   await new Promise((resolve, reject) => {
          //     const img = new Image();
          //     img.onload = resolve;
          //     img.onerror = reject;
          //     img.src = template.props.profilePicture;
          //   });
          // }
          console.log(template);
          
          const pdfBlob = await pdf(template).toBlob();
          const pdfURL = URL.createObjectURL(pdfBlob);
          const loadedPdf = await pdfjsLib.getDocument(pdfURL).promise;
          setPdfData(loadedPdf);
          setPageCount(loadedPdf.numPages);
        } catch (error) {
          console.error('Error generating PDF:', error);
          setError(error);
        }
      };
      
      const hasPersonalia = localStorage.getItem('personalia') !== null;
      if (hasPersonalia && template) {
        generatePdfBlob();
      }
    }, [template]);
  
    const renderPage = async (pageNum) => {
      if (!pdfData) return;
      // if (!pdfData || isRendering) return;
      setIsRendering(true);
  
      const page = await pdfData.getPage(pageNum);
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
  
      await page.render({ canvasContext: context, viewport }).promise;
      setIsRendering(false);
    };
  
    useEffect(() => {
      if (pdfData) {
        renderPage(currentPage);
      }
    }, [currentPage, pdfData]);
  
    const goToPreviousPage = () =>
      setCurrentPage((prev) => Math.max(prev - 1, 1));
    const goToNextPage = () =>
      setCurrentPage((prev) => Math.min(prev + 1, pageCount));
  
    return (
      <div style={{ textAlign: "center" }} className="pdfViewer">
        <canvas ref={canvasRef} style={{ border: "1px solid #ccc" }} />
        <div style={{ marginTop: "10px" }}>
          <ArrowLeft 
            onClick={goToPreviousPage} 
            disabled={currentPage === 1} 
            className="arrowLeft"
          />
          <span style={{ margin: "0px 50px", position: "absolute", bottom: -100, left: 0, right: 0, textAlign: "center", fontSize: 36 }}>
            {currentPage} / {pageCount}
          </span>
          <ArrowRight 
            onClick={goToNextPage} 
            disabled={currentPage === pageCount} 
            className="arrowRight"
          />
        </div>
      </div>
    );
};
  
export default PdfViewer2;
  