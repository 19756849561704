import Input from "../components/Input"
import { useState } from "react"
import handleInputChange from "../components/HandleInputChange";
import "../css/Contact.css";
import axios from "axios";
import CTA2 from "../components/buttons/CTA2";
export default function Contact() {
  const [formData, setFormData] = useState({
    "firstName": '',
    "email": '',
    "msg": ''
  })
  const [errorMsg, setErrorMsg] = useState({
    isErrorMsg: false,
    errorMsg: ''
  });
  const [successMsg, setSuccessMsg] = useState({
    isSuccessMsg: false,
    successMsg: ''
  });

  const handleSubmit = () => {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/contact`, formData, { withCredentials: true })
      .then((result) => {
        // Handle successful submission (e.g., show a success message)
        setSuccessMsg({
          isSuccessMsg: true,
          successMsg: 'Meldingen er sendt videre til vår avdeling. Vi tar kontakt med deg snart.'
        });
        
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg({
          isErrorMsg: true,
          errorMsg: 'An error occurred while submitting the form. Please try again.'
        });
      });
  }

  return (
    <div>
      <div className="contactForm">
        <h1>Kontakt oss</h1>
        <div className="contactInfo">
          <div><span>Telefon:</span> <a href="tel:+4746425988">+47 46 42 59 88</a></div>
          <div><span>E-post:</span> <a href="mailto:support@lagecv.no">support@lagecv.no</a></div>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div>
            <Input 
              type="text" 
              placeholder="fornavn" 
              value={formData.firstName} 
              onChange={(value) => handleInputChange("firstName", value, setFormData)}
            />
            <Input 
              type="email" 
              placeholder="email" 
              value={formData.email} 
              onChange={(value) => handleInputChange("email", value, setFormData)}
            />
          </div>
          <div>
            <textarea 
              value={formData.msg} 
              placeholder="skriv en melding" 
              onChange={(e) => handleInputChange("msg", e.target.value, setFormData)}
            />
          </div>
        <CTA2 onClick={handleSubmit} name="Send inn" />
        </form>
        {/* <button onClick={handleSubmit} className="cta2">Send inn</button> */}
      </div>
      {errorMsg.isErrorMsg && <p className="error-message">{errorMsg.errorMsg}</p>}
      {successMsg.isSuccessMsg && <p className="success-message">{successMsg.successMsg}</p>}
    </div>
  )
}
