import "../css/PopupModal.css";
import axios from "axios";
import { useState } from "react";



export default function PopupPayModal({ setPopupPayModal }) {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleClick = (e) => {
    // setPopupModal(false);
    if (e.target.classList.contains("popupModalContent")) {
      setPopupPayModal(false);
    }
  };

  const handlePay = (e) => {
    e.preventDefault();
    if(e.target.value === "") {
      alert("Du må skrive inn telefonnummer");
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/vipps-setup`,
        {
          phoneNumber,
        },
        { withCredentials: true }
      )
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="popupModal" onClick={(e) => handleClick(e)}>
      <div
        className="popupModalContent"
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setPopupPayModal(false);
          }
        }}
      >
        <div
          className="popupModalContentForm"
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setPopupPayModal(false);
            }
          }}
        >
          <h2>Betal med Vipps</h2>
          <div className="popupPayModalText">
          <p>
            Det koster 19 kroner for å laste ned CVen din som en pdf versjon.{" "}
          </p>
          <p>
            Etter 7 dager vil prisen øke til 199 kroner hvor du har mulighet til
            å oppdatere CVen din, få tips og tricks til intervju og mulighet til
            å bruke søknadsskriveren vår til å søke på jobber. 
          </p>
          <p>Betal med Vipps for å fullføre registreringen.</p>
          </div>
          {/* <h2>Login</h2>
                <form>
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <button type="submit">Login</button>
                </form> */}
     
          <form>
            <input
              type="text"
              required
              id="phoneNumber"
              placeholder="Telefonnummer"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button type="submit" onClick={(e) => handlePay(e)}>
              Betal
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
