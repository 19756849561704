import { View, Image } from "@react-pdf/renderer";

// Helper to render stars
const renderStars = (count, styles) => {
  if (count !== "" && count !== 0 && count !== undefined) {
    return (
      <View style={styles.starContainer}>
        {[...Array(parseInt(count))].map((_, i) => (
          <Image style={styles.stars} src="/Star.png" key={i} />
        ))}
      </View>
    );
  }
  return null;
};

export default renderStars;
