import React, { useState, useEffect } from "react";
import "../../css/RichTextStyling.css";
import axios from "axios";
import { ReactComponent as AIIcon } from "../../img/ai-icon.svg";
import { useLocalStorage } from '../../hooks/useLocalStorage';
const EducationEditor = ({ content, setContent, index, name, updateFormData }) => {
  const [localContent, setLocalContent] = useState('');
  const [modalContent, setModalContent] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const modalId = `myModal${name}-${index}`;
  const storageKey = name.toLowerCase();
  const [storedData, setStoredData] = useLocalStorage(storageKey, {});
  useEffect(() => {
    try {
      const savedData = localStorage.getItem(storageKey);
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        if (parsedData[name] && parsedData[name][index]?.formData?.oppsummering) {
          setLocalContent(parsedData[name][index].formData.oppsummering);
        } else if (content) {
          setLocalContent(content);
        }
      } else if (content) {
        setLocalContent(content);
      }
    } catch (error) {
      console.error('Error parsing localStorage data:', error);
      if (content) {
        setLocalContent(content);
      }
    }
  }, [content, index, name]);

  const handleModal = () => {
    setModalContent("");
    setIsGenerating(true);

    document.getElementById(modalId).style.display = "block";
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/generate-text`,
        { content: localContent },
        { withCredentials: true }
      )
      .then((response) => {
        setModalContent(response.data.content);
        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        setModalContent("An error occurred while generating text.");
      });
  };

  const updateLocalStorage = (newContent) => {
      try {
      const savedData = localStorage.getItem(storageKey);
      const lastUpdated = new Date().toISOString();
      let storedData = savedData ? JSON.parse(savedData) : {};
      
      if (!storedData[name]) {
        storedData[name] = [];
      }
      
      while (storedData[name].length <= index) {
        storedData[name].push({ formData: { oppsummering: '' } });
      }
      
      storedData[name][index].formData.oppsummering = newContent;
      storedData.lastUpdated = lastUpdated;
      
      setStoredData(storedData);
      // localStorage.setItem(storageKey, JSON.stringify(storedData));
    } catch (error) {
      console.error('Error updating localStorage:', error);
    }
  };

  const closeModal = () => {
    document.getElementById(modalId).style.display = "none";
  };

  const handleAIIconClick = () => {
    handleModal();
  };

  const handleAccept = () => {
    updateLocalStorage(modalContent);
    setLocalContent(modalContent);
    setContent(prevContent => ({
      ...prevContent,
      [index]: modalContent
    }));
    updateFormData(modalContent, index);
    closeModal();
  };

  const handleTextAreaChange = (e) => {
    const newValue = e.target.value;
    updateLocalStorage(newValue);
    setLocalContent(newValue);
    setContent(prevContent => ({
      ...prevContent,
      [index]: newValue
    }));
    updateFormData(newValue, index);
  };

  return (
    <div className="rich-text-editor">
      <div className="toolbar">
        <button className="toolbar-button">
          <strong>B</strong>
        </button>
        <button className="toolbar-button">
          <em>I</em>
        </button>
        <button className="toolbar-button ai-icon">
          <AIIcon onClick={handleAIIconClick} />
        </button>
      </div>
      <textarea
        className="text-area"
        placeholder={`Beskriv din ${name === "Utdanning" ? "utdanning" : "arbeidserfaring"}`}
        value={localContent}
        onChange={handleTextAreaChange}
      ></textarea>

      <div id={modalId} className="modal">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          {isGenerating ? (
            <p>Genererer...</p>
          ) : (
            <>
              <p>Generert av AI:</p>
              <textarea
                className="modal-textarea"
                value={modalContent}
                onChange={(e) => setModalContent(e.target.value)}
              ></textarea>
              <button
                className="accept-button"
                onClick={handleAccept}
              >
                Aksepter
              </button>
              <button className="regenerate-button" onClick={handleModal}>
                Regenerer
              </button>
            </>
          )}
        </div>
      </div> 
    </div>
  );
};

export default EducationEditor;
