import axios from "axios";

async function selectedCVMalDeets(selectedCVMal, setCVMal) {
    // if (selectedCVMal) {
    //   axios
    //     .post(`${process.env.REACT_APP_BACKEND_URL}/api/cvmal/mal`, {
    //       cvMal: selectedCVMal,
    //     })
    //     .then((response) => {
    //       setCVMal(response.data[0]);
    //     });
    // }
  }

  export default selectedCVMalDeets;