import "../../css/buttons/CTA.css"
import { Link, useState } from "react-router-dom";

export default function CTA({ name, isMalChosen }) {

    return(
        <div className="cta">

          {isMalChosen ? <Link to="/lagecv">{name}</Link> : <Link to="/velgeMal">{name}</Link>}
        </div>
    )
}