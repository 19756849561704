import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CVAccordion from "../components/CVAccordion";
import TemplatesList from "../components/Templates";
import PricesComponent from "../components/Prices";
import PrimaryButton from "../components/buttons/PrimaryButton.js";
import "../css/LandingPage.css"; // We'll create this CSS file


export default function LandingPage() {
  const [isMalChosen, setIsMalChosen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [visibleTemplates, setVisibleTemplates] = useState([]);

 
  const navigate = useNavigate();


  const [isTransitioning, setIsTransitioning] = useState(false);

 
  return (
    <div className="landing-page">
      <section className="hero-section">
        <div className="hero-content">
          {/* <h1>Bygg din CV på rekordtid: Velg en mal og få AI-hjelp til å finjustere innholdet</h1> */}
          <h1>Lag din perfekte CV med hjelp av AI</h1>
          <p>
            Bruk vår CV bygger med integrert AI for å lage din perfekte CV. Velg
            blant flere CV maler og flere forskjellige farger og stiler. Få også
            en søknad skrevet av deg av vår egen SøknadsSvein. Få full tilgang
            til alle funksjoner når du logger inn og aktiverer ditt abonnement.
            La oss hjelpe deg med å få jobben!
          </p>
          <PrimaryButton name="Start her" isMalChosen={false} path="/velgeMal" />
        </div>
        <div className="hero-image">
          <img
            src={process.env.REACT_APP_FRONTEND_URL + "/img/manny.jpg"}
            alt="hero"
          ></img>
        </div>
      </section>
      <section className="features-section">
        <h2>Vi hjelper deg med å få jobben</h2>
        <p>
          Med vår AI bygger samt våre profesjonelle cver, gjør vi at du stikker
          deg ut fra de andre søknadene.
        </p>
        <div className="features-container">
          <div className="feature">
            <img className="feature-image" src={process.env.REACT_APP_FRONTEND_URL+"/img/applyImg.png"} alt="apply"></img>
            <h3>AI Bygger</h3>
            <p>
              Bygg din egen CV med vår AI bygger. Få hjelp til å lage
              profesjonelle beskrivelser.
            </p>
          </div>
          <div className="feature">
            <img className="feature-image" src={process.env.REACT_APP_FRONTEND_URL+"/img/søknadssvein.png"} alt="apply"></img>
            <h3>SøknadsSvein</h3>
            <p>Få en søknad skrevet av vår egen SøknadsSvein.</p>
          </div>
          <div className="feature">
            <img className="feature-image" src={process.env.REACT_APP_FRONTEND_URL+"/img/responsiveCV.png"} alt="apply"></img>
            <h3>Responsiv CV-bygger</h3>
            <p>
              Bygg en overbevisende curriculum vitae enkelt, også fra mobil.
            </p>
          </div>
        </div>
      </section>

      <TemplatesList />

      <section className="why-choose-us">
        <div className="why-choose-us-container">
        <h2>Hvorfor velge oss?</h2>
        <p>
          Vi har samarbeid med store og små bedrifter for å kunne hjelpe deg til
          å legge inn relevante opplysninger i din CV.{" "}
        </p>
        <p>Vi jobber også med NAV.</p>
        <p>
          Dersom du ønsker at vi skal se gjennom din CV, så kan du sende den til{" "}
          <a href="mailto:support@lagecv.no">support@lagecv.no</a>
        </p>
        <PrimaryButton name="Start her" isMalChosen={false} path="/velgeMal" />
        </div>
        <div className="why-choose-us-image hero-image"><img src={process.env.REACT_APP_FRONTEND_URL+"/img/Img Getty.jpg"} alt="nav"></img></div>
      </section>
 

      <PricesComponent isMalChosen={isMalChosen} />

      <section className="cv-info-section">
        <h2>Ofte stilte spørsmål</h2>
        <CVAccordion />
      </section>
    </div>
  );
}
