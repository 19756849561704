import "../css/PopupModal.css";
import Login from "../pages/Login";

export default function PopupModal({setPopupModal}) {
    const handleClick = (e) => {
        // setPopupModal(false);
        if(e.target.classList.contains("popupModalContent")) {
            setPopupModal(false);
        }
    }
    return (
        <div className="popupModal" onClick={(e) => handleClick(e)}>
            <div className="popupModalContent" onKeyDown={(e) => {
            if (e.key === 'Escape') {
                setPopupModal(false);
            }
        }}>
                <div className="popupModalContentForm" onKeyDown={(e) => {
            if (e.key === 'Escape') {
                setPopupModal(false);
            }
        }}>

                {/* <h2>Login</h2>
                <form>
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <button type="submit">Login</button>
                </form> */}
                <Login msg="Du må logge inn for å kunne laste ned CVen" redirect={false}/>
                </div>
        </div>
        </div>
    )
}