// import logo from "../img/LageCV.png"
import { ReactComponent as LageCV } from "../img/LageCV.svg"
import "../css/Logo.css"

export default function Logo() {
    return (
        <div className="logo-container">
            <LageCV className="logo-svg" />
        </div>
    );
}
