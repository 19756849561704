import { Link } from "react-router-dom";
import "../css/Footer.css"
import Logo from "../components/Logo"
import { useAuth } from "./AuthContext";
import { useState } from "react";
import axios from "axios";

export default function Footer() {
    const { isAuthenticated } = useAuth();
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleLogout = () => {
        deleteCookie();
    }

    const deleteCookie = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/deleteCookie`, { withCredentials: true }).then((result) => {
            window.location.href = "/";
        })
    }

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    }

    const accordionData = [
        {
            title: "Produkt",
            content: [
                <Link to="/blogg">Blogg</Link>,
                <Link to="/prices">Priser</Link>,
                isAuthenticated ? <button className="logout-button" onClick={handleLogout}>Logg ut</button> : <Link to="/login">Logg inn</Link>
            ]
        },
        {
            title: "Karriere",
            content: [
                <Link to="/about">Om oss</Link>,
                <Link to="/privacy">Personvern</Link>,
                <Link to="/terms">Salgsbetingelser</Link>
            ]
        },
        {
            title: "Selskap",
            content: [
                <div>932 299 658 MVA</div>,
                <div><a href="tel:+4746425988">+47 46 42 59 88</a></div>,
                <Link to="/faq">Hjelpesenter</Link>,
                <Link to="/contact">Kontakt oss</Link>
            ]
        }
    ];

    return (
        <div className="footer">
            <div className="footer-desktop">
                <div className="footer-container">
                    <div>
                       <Link to="/"><Logo></Logo></Link>
                    </div>
                    <div>932 299 658 MVA</div>
                    <div><a href="tel:+4746425988">+47 46 42 59 88</a></div>
                    <div><Link to="/faq">Hjelpesenter</Link></div>
                </div>
                <div className="footer-container"> 
                    <div className="footer-title">Ofte besøkte sider</div>
                    <div><Link to="/blogg">Blogg</Link></div>
                    <div><Link to="/prices">Priser</Link></div>
                    <div>{isAuthenticated ? <button className="logout-button" onClick={handleLogout}>Logg ut</button> : <Link to="/login">Logg inn</Link>}</div>
                </div>
                <div className="footer-container"> 
                    <div className="footer-title">Om Lage CV</div>
                    <div><Link to="/about">Om oss</Link></div>
                    <div><Link to="/privacy">Personvern</Link></div>
                    <div><Link to="/terms">Salgsbetingelser</Link></div>
                </div>
            </div>
            <div className="footer-mobile">
            
                {accordionData.map((section, index) => (
                    <div key={index} className="accordion-item-footer">
                        <button
                            className={`accordion-title ${activeAccordion === index ? 'active' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            {section.title}
                        </button>
                        {activeAccordion === index && (
                            <div className="accordion-content">
                                {section.content.map((item, i) => (
                                    <div className="accordion-content-inner" key={i}>{item}</div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
                    <Logo />
            </div>
        </div>
    )
}
