import React from 'react';
import { ReactComponent as TrashIconSVG } from "../img/Delete.svg";

const ComponentWrapper = ({ component, onDelete }) => {
  return (
    <div className="component-wrapper">
      <div className="component-header">
        <div className="trash-icon" onClick={onDelete}>
          <TrashIconSVG />
        </div>
      </div>
      {component}
    </div>
  );
};

export default ComponentWrapper; 