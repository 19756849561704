import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import getCVMal from "../components/getCVMal.js";
import { useAuth } from '../components/AuthContext';
import { ReactComponent as ArrowRight } from '../img/arrowRight.svg';
import { ReactComponent as ArrowLeft } from '../img/arrowLeft.svg';
import { useLocalStorage } from '../hooks/useLocalStorage';
import '../css/ChooseTemplate.css';

export default function ChooseTemplate() {
    const [cvTemplates, setCvTemplates] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [storedData, setStoredData] = useLocalStorage('selectedCV', {});
    const [storedCvId, setStoredCvId] = useLocalStorage('cvId', {});
    
    const { setSelectedTemplate, isTransitioning, setIsTransitioning, isAuthenticated } = useAuth();
    // const [selectedTemplate, setSelectedTemplate] = useState(null);
    // const [isTransitioning, setIsTransitioning] = useState(false);
    const navigate = useNavigate();

    const handleTemplateSelect = (template) => {
        
        if (isAuthenticated) {
            setStoredData(template);
            // localStorage.setItem('selectedCV', JSON.stringify(template));
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cv/chooseCvTemplate`, {
                cvTemplate: template, cvId: storedCvId.cvId
            }, {withCredentials: true} ).then((response) => {
                console.log(response);
                setIsTransitioning(true);
                setTimeout(() => {
                    setSelectedTemplate(template);
                    setStoredCvId( response.data.cvId);
                    // localStorage.setItem('cvId', response.data.cvId);
                    navigate("/lagecv");
                    setIsTransitioning(false);
                }, 500);
            });
        } else {
            setStoredData(template);
            // localStorage.setItem('selectedCV', JSON.stringify(template));
            setIsTransitioning(true);
            setTimeout(() => {
                setSelectedTemplate(template);
    
                navigate("/lagecv");
                setIsTransitioning(false);
            }, 500);
            }
    };

    useEffect(() => {
        getCVMal({setCvTemplates});
    }, []);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cvTemplates.length);
    }, [cvTemplates.length]);

    const prevSlide = useCallback(() => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + cvTemplates.length) % cvTemplates.length);
    }, [cvTemplates.length]);

    const handleTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 75) {
            nextSlide();
        }

        if (touchStart - touchEnd < -75) {
            prevSlide();
        }
    };

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowRight') {
            nextSlide();
        } else if (event.key === 'ArrowLeft') {
            prevSlide();
        }
    }, [nextSlide, prevSlide]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div className={`template-selection ${isTransitioning ? 'fade-out' : ''}`}>
            <h1>Velg CV mal</h1>
            <div 
                className="template-slider"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <ArrowLeft className="arrow arrow-left" onClick={prevSlide} />
                <div className="template-container">
                    {cvTemplates.length > 0 && (
                        <div
                            key={cvTemplates[currentIndex].id}
                            className="template-item"
                            onClick={() => handleTemplateSelect(cvTemplates[currentIndex])}
                        >
                            <h2>{cvTemplates[currentIndex].name}</h2>
                            <img className="cvTemplateImages" src={process.env.REACT_APP_FRONTEND_URL+`${cvTemplates[currentIndex].previewImage}`} alt={cvTemplates[currentIndex].name} />
                        </div>
                    )}
                </div>
                <ArrowRight className="arrow arrow-right" onClick={nextSlide} />
            </div>
            <div className="progress-dots">
                {cvTemplates.map((_, index) => (
                    <div 
                        key={index} 
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
}
