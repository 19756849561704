import DangerButton from "../components/buttons/DangerButton"
import SecondaryButton from "../components/buttons/SecondaryButton"
import TextButton from "../components/buttons/TextButton"
import GhostButton from "../components/buttons/GhostButton"
import CTA3 from "../components/buttons/CTA3"
import CTA from "../components/buttons/CTA"
import CTA2 from "../components/buttons/CTA2"
import OutlineButton from "../components/buttons/OutlineButton"
import PrimaryButton from "../components/buttons/PrimaryButton"

export default function CTAS() {
  return (
    <div className="ctas-page">

      <h1>CTAS</h1>
    <div className="ctas">
      <PrimaryButton name="Primary" />
      <SecondaryButton name="Secondary" />
      <TextButton name="Text" />
      <GhostButton name="Ghost" />
      <DangerButton name="Danger" />
      <CTA3 name="CTA3" isMalChosen={false} />
      <CTA name="CTA" isMalChosen={false} />
      <CTA2 name="CTA2" isMalChosen={false} />
    </div>
    </div>
  );
}
