import "../css/Profile.css";
import CTA from "../components/buttons/CTA";
import Input from "../components/Input";
import CTA2 from "../components/buttons/CTA2";
import { useEffect, useState, useCallback } from "react";
import handleInputChange from "../components/HandleInputChange";
import axios from "axios";
import lodash from "lodash";
import { useNavigate } from "react-router-dom";
import DangerButton from "../components/buttons/DangerButton";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { useAuth } from "../components/AuthContext";

export default function Profile() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const { userInfo } = useAuth();

  const [showDeleted, setShowDeleted] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showCVs, setShowCVs] = useState(true);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [cvs, setCvs] = useState([]);
  const [message, setMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    getUser();
    fetchCVs();
    fetchSubscriptions();
  }, []);

  async function getUser() {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/profile`, {
        withCredentials: true,
      })
      .then((result) => {
        let data = result.data.data;

        setFormData((prevState) => ({
          ...prevState, // Spread in the previous state
          firstName: data.firstName, // Update with new data
          lastName: data.lastName,
          email: data.email,
        }));
      });
  }

  const isAnyFieldNotEmpty = () => {
    return Object.entries(formData)
      .filter(([key, value]) => value.trim() !== "") // Filter out empty values
      .map(([key, value]) => ({ [key]: value }));
  };

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleInput = debounce(() => {
    // Send data to backend
    handleSubmit();
  }, 2000);

  const handleSubmit = () => {
    const nonEmptyValues = isAnyFieldNotEmpty();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isEmail = emailRegex.test(nonEmptyValues[2].email);

    if (isEmail) {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/user/profile`,
          { email: userInfo, user: nonEmptyValues },
          { withCredentials: true }
        )
        .then((result) => {
          if (result.status(201)) {
            window.location.href = "/login";
          }
        });
    }
  };

  const deleteCookie = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/deleteCookie`, {
        withCredentials: true,
      })
      .then((result) => {
        window.location.href = "/login";
      });
  };

  const showInputField = () => {
    setShowDeleted(true);
  };

  const deleteProfile = (e) => {
    let value = e.target.parentElement.firstChild.value;
    if (value.toUpperCase() == "SLETT MEG") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/user/deleteProfile`, {
          withCredentials: true,
        })
        .then((result) => {
          if (result.status == 200) {
            window.location.href = "/login";
          }
        });
    }
  };

  const selectProfile = (e) => {
    setShowProfile(true);
    setShowCVs(false);
    setShowSubscriptions(false);
  };

  const selectSubscriptions = (e) => {
    setShowSubscriptions(true);
    setShowProfile(false);
    setShowCVs(false);
  };

  const selectCV = (e) => {
    setShowProfile(false);
    setShowCVs(true);
    setShowSubscriptions(false);
  };

  const fetchCVs = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/fetchCVs`,
        {},
        { withCredentials: true }
      )
      .then((response) => {
        if (response.status === 200) {
          setCvs(response.data);
        } else {
          console.error("Failed to fetch CVs");
        }
      })
      .catch((error) => {
        console.error("Error fetching CVs:", error);
      });
  };

  const fetchSubscriptions = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/subscriptions`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setSubscriptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  };

  const cancelSubscription = (agreementId) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/cancelSubscription`,
        { agreementId: agreementId },
        { withCredentials: true }
      )
      .then((response) => {})
      .catch((error) => {
        if (error.response.status === 400) {
          setMessage("Abonnement allerede avbrutt");
        } else {
          setMessage("Noe gikk galt");
        }
        console.error("Error canceling subscription:", error);
      });
  };

  const deleteCV = (idcv) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/deleteCV`,
        { idcv: idcv },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.status == 200) {
          setMessage("CV slettet");
          setTimeout(() => setMessage(""), 5000);
          fetchCVs();
        }
      });
  };

  const editCV = (idcv, template) => {
    let templateId = template || 5;

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cvmal/mal`,
        { cvMal: templateId },
        { withCredentials: true }
      )
      .then((response) => {
        navigate(`/lageCV/?idcv=${idcv}`, {
          state: { template: response.data[0], idcv: idcv },
        });
      });

    // } else {

    // }
    // navigate(`/lageCV/?idcv=${idcv}`, { state: { template: "Mainla", idcv: idcv } });
  };

  const copyCV = (idcv) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/copyCV`,
        { idcv: idcv },
        { withCredentials: true }
      )
      .then((result) => {
        if (result.status == 200) {
          setMessage("CV kopiert");
          setTimeout(() => setMessage(""), 5000);
        }
      });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const createNewCV = async () => {
    try {
      // Get current design settings
      const designSettings = {
        fonts: JSON.parse(localStorage.getItem('fontFamily') || '{"fontFamily": "Inter"}'),
        colors: JSON.parse(localStorage.getItem('colorFormData') || '{}'),
        fontSize: JSON.parse(localStorage.getItem('fontSettings') || '{"size": 10}')
      };

      // Create new CV with design settings
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cv/create`, 
        designSettings,
        { withCredentials: true }
      );

      if (response.status === 200) {
        const newCvId = response.data.cvId;
        
        // Clear all CV-related data from localStorage except design settings
        const keysToKeep = ['colorFormData', 'fontFamily', 'fontSettings', 'selectedCV'];
        
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (!keysToKeep.includes(key)) {
            localStorage.removeItem(key);
          }
        }

        // Save the new CV ID to localStorage
        localStorage.setItem('cvId', newCvId);

        // Navigate to CV template selection
        navigate('/lagecv');
      }
    } catch (error) {
      console.error('Error creating new CV:', error);
      setMessage("Kunne ikke opprette ny CV");
    }
  };

  return (
    <div className="profile">
      <div className="nav-container">
        <div className="hamburger2" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className={`menu ${isMenuOpen ? "open" : ""}`}>
          <h2
            onClick={() => {
              selectCV();
              toggleMenu();
            }}
          >
            Mine CVer
          </h2>
          <h2
            onClick={() => {
              selectProfile();
              toggleMenu();
            }}
          >
            Min profil
          </h2>
          <h2
            onClick={() => {
              selectSubscriptions();
              toggleMenu();
            }}
          >
            Mitt abonnement
          </h2>
        </div>
      </div>

      {/* CV */}
      {showCVs ? (
        <div className="profileContainer">
          <h1>Mine CVer</h1>
          <p className="message">{message}</p>
          <div className="cvRow">
            {cvs.map((cv, index) => (
              <div key={index} className="cvContainer">
                <div>{cv.cvTemplate}</div>
                <div>{cv.last_cv_update}</div>
                <div className="actions">
                  <div onClick={() => editCV(cv.idcv, cv.cvTemplate)}>endre</div>
                  <div onClick={() => deleteCV(cv.idcv)}>slett</div>
                  <div onClick={() => copyCV(cv.idcv)}>kopier</div>
                  <div>last ned</div>
                </div>
              </div>
            ))}
          </div>
          <div className="newCVButton">
            <PrimaryButton
              name="Lag ny CV"
              onClick={createNewCV}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {showProfile ? (
        <div className="createUser">
          <h1>Rediger personalia</h1>
          <div>
            <form>
              <div>
                <Input
                  type="text"
                  placeholder={formData.firstName || "fornavn"}
                  name={formData.firstName}
                  onChange={(value) =>
                    handleInputChange("firstName", value, setFormData)
                  }
                  onBlur={handleSubmit}
                ></Input>
                <Input
                  type="text"
                  placeholder={formData.lastName || "etternavn"}
                  name={formData.lastName}
                  onChange={(value) =>
                    handleInputChange("lastName", value, setFormData)
                  }
                  onBlur={handleSubmit}
                ></Input>
              </div>
              <div>
                <Input
                  type="email"
                  placeholder={formData.email || "email"}
                  name={formData.email}
                  onChange={(value) =>
                    handleInputChange("email", value, setFormData)
                  }
                  onBlur={handleSubmit}
                ></Input>
                <Input
                  type="password"
                  placeholder="passord"
                  name={formData.password}
                  onChange={(value) =>
                    handleInputChange("password", value, setFormData)
                  }
                  onBlur={handleSubmit}
                ></Input>
              </div>
            </form>
            <div className="actionContainer">
              <PrimaryButton
                name="logg ut"
                onClick={deleteCookie}
                path="/login"
              ></PrimaryButton>
              <br></br>
              <div>
                {showDeleted ? (
                  <form>
                    <Input
                      type="text"
                      placeholder="skriv SLETT MEG"
                      name="SLETT MEG"
                      onChange={(value) => {}}
                      onBlur={(value) => {}}
                    ></Input>
                    <CTA2
                      name="SLETT"
                      onClick={(e) => deleteProfile(e)}
                      path="/profile"
                    ></CTA2>
                  </form>
                ) : (
                  <div></div>
                )}
                {!showDeleted ? (
                  <DangerButton
                    name="slett min profil"
                    onClick={showInputField}
                    path="/profile"
                  ></DangerButton>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {showSubscriptions ? (
        <div className="profileContainer">
          <h1>Mitt abonnement</h1>
          <div className="subscriptionContainer">
            <div className="subscription">
              {!subscriptions.length ? (
                <div>Ingen abonnementer</div>
              ) : (
                subscriptions.map((subscription, index) => (
                  <div key={subscription.idpayment}>
                    <div>{subscription.date}</div>
                    <div>{subscription.subscriptionStatus}</div>
                    <div>{subscription.uuid}</div>
                    <div>{subscription.agreementId}</div>
                    <div>{subscription.chargeId}</div>
                    {subscription.subscriptionStatus === "ACTIVE" ? (
                      <button
                        onClick={() =>
                          cancelSubscription(subscription.agreementId)
                        }
                      >
                        cancel
                      </button>
                    ) : (
                      <div></div>
                    )}
                    <p className="message" style={{ backgroundColor: "red" }}>
                      {message}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
