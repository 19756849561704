import Input from "../components/Input"
import CTA2 from "../components/buttons/CTA2"
import { useState } from "react";
import axios from "axios";
import handleSubmission from "../components/HandleSubmission";
import handleInputChange from "../components/HandleInputChange";
import { Link } from "react-router-dom"
import { useAuth } from "../components/AuthContext";

export default function Register() {


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    })

    const [errorMsg, setErrorMsg] = useState({
        isErrorMsg: false,
        errorMsg: ''
    });

    const {isAuthenticated, setIsAuthenticated} = useAuth();


    return (
        <div className="createUser">
            <h1>Registrer</h1>
            <div onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleSubmission(formData, "register", setErrorMsg, true, setIsAuthenticated)
                }
            }}>
                <form>
                    <div>
                        <Input type="text" placeholder="fornavn" name={formData.firstName} onChange={(value) => handleInputChange("firstName", value, setFormData)}></Input>
                        <Input type="text" placeholder="etternavn" name={formData.lastName} onChange={(value) => handleInputChange("lastName", value, setFormData)}></Input>

                    </div>
                    <div>
                        <Input type="text" placeholder="email" name={formData.email} onChange={(value) => handleInputChange("email", value, setFormData)}></Input>
                        <Input type="password" placeholder="password" name={formData.password} onChange={(value) => handleInputChange("password", value, setFormData)}></Input>


                    </div>
                </form>
                <Link to="/login" className="url">Allerede bruker?</Link>
                <CTA2 name="lag bruker" path="/profile" onClick={() => handleSubmission(formData, "register", setErrorMsg, true, setIsAuthenticated)}></CTA2>

                {/* <CTA2 name="lag bruker" onClick={() => handleSubmission(formData, "register", setShowError)}></CTA2> */}
                {/* path="/profile"  */}
            {errorMsg.isErrorMsg ? <p className="errorMsg">{errorMsg.errorMsg}</p>: <p></p>}
            </div>
        </div>
    )
}