import PricesComponent from '../components/Prices';
import { useState } from 'react';

export default function Prices() {
  const [isMalChosen, setIsMalChosen] = useState(false);
  return (
    <div>
      <h1>Priser</h1>
      <PricesComponent isMalChosen={isMalChosen}/>
    </div>
  );
}
