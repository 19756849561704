  // Helper function to safely access nested properties
  const safeGet = (obj, path, fallback = "") => {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : fallback),
        obj
      );
  };


  export default safeGet;