import '../css/About.css';

export default function About() {
  return (
      <div className="about-container">
      <h1>Om oss</h1>

      <p>
        LageCV.no ble startet av arbeidsgivere, for jobbsøkere. Som erfarne
        arbeidsgivere vet vi nøyaktig hva som skal til for at en CV skal skille
        seg ut og fange interessen til rekrutterere. Vi har sett mange CV-er som
        ikke gir et godt førsteinntrykk, og vi forstår hvor viktig det er å
        presentere seg på en strukturert og profesjonell måte. Derfor har vi
        utviklet en tjeneste som hjelper deg med å lage en CV som gir deg best
        mulig sjanse til å sikre deg et intervju.
      </p>

      <h2>Vi vet hva arbeidsgivere ser etter</h2>
      <p>
        Med mange års erfaring fra ansettelsesprosesser har vi sett tusenvis av
        CV-er, og vi vet hva som fungerer – og hva som ikke gjør det. En godt
        skrevet og oversiktlig CV kan være forskjellen mellom å bli kalt inn til
        intervju eller ikke. Vi har derfor utviklet LageCV.no for å hjelpe deg
        med å lage en CV som raskt og effektivt viser dine viktigste ferdigheter
        og erfaringer på en måte som imponerer arbeidsgivere.
      </p>

      <h2>Utviklet av arbeidsgivere, for jobbsøkere</h2>
      <p>
        LageCV.no er skapt av folk som selv sitter på den andre siden av bordet.
        Vi vet at arbeidsgivere bruker kort tid på hver CV, og derfor må din CV
        gjøre et sterkt førsteinntrykk. Med vår plattform kan du enkelt lage en
        CV som både er profesjonell og skreddersydd for det norske
        arbeidsmarkedet, med fokus på det som virkelig betyr noe for
        arbeidsgivere.
      </p>

      <h2>Førsteinntrykket teller</h2>
      <p>
        Din CV er ofte det første en arbeidsgiver ser, og det er ditt første
        sjanse til å skille deg ut fra mengden. Vi har utviklet en brukervennlig
        tjeneste som gjør det enkelt for deg å lage en CV som er både
        profesjonell og oversiktlig, og som får frem dine beste egenskaper. Vår
        erfaring som arbeidsgivere har hjulpet oss med å identifisere hva som
        virkelig får en CV til å skinne.
      </p>

      <h2>Vårt mål – Din suksess</h2>
      <p>
        Vi ønsker at du skal lykkes. LageCV.no er laget med en klar visjon: å gi
        deg verktøyene som gjør deg best mulig forberedt til søknadsprosessen.
        Vi vet at en god CV gir deg et fortrinn, og vi er her for å hjelpe deg
        med å nå dine karrieremål. Med en CV fra LageCV.no vil du kunne
        presentere deg selv på best mulig måte – og øke sjansene dine for å bli
        invitert til intervju.
      </p>

      <p>
        La oss hjelpe deg med å lage en CV som virkelig viser hvem du er og
        hvorfor du er den rette kandidaten. Ta det første skrittet mot din neste
        jobb i dag!
      </p>
      </div>
  );
}
