// import Templates from "../components/Templates"
// import TemplateSlider from "../components/TemplateSlider"
import TemplatesList from "../components/Templates"
export default function Templates() {
  return (
    <div>
      <h1>Våre CV-maler</h1>
        <TemplatesList />
    </div>
  );
}
