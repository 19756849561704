import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Link,
    Canvas,
    Font,
    Svg,
    Path,
  } from "@react-pdf/renderer";

const registerFonts = () => {
  Font.register({
    family: "OpenSans",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf",
        fontWeight: 400,
      }, // Regular
      {
        src: "https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXNhE.ttf",
        fontWeight: 700,
      }, // Bold
    ],
  });
  
  Font.register({
    family: "Poppins",
    fonts: [
      { src: "../../fonts/Poppins-Regular.ttf", fontWeight: "normal" }, // font-style: normal, font-weight: normal
      { src: "../../fonts/Poppins-Bold.ttf", fontWeight: "bold"},
    ],
  });
  
  Font.register({
    family: "Inter",
    fonts: [
      { src: "../../fonts/Inter.ttf", fontWeight: 400 }, // font-style: normal, font-weight: normal
    ],
  });


  Font.register({
    family: "Lato",
    fonts: [
      { src: "../../fonts/Lato.ttf", fontWeight: 400 }, // font-style: normal, font-weight: normal
    ],
  });
};
Font.registerHyphenationCallback(word => [word]);
export default registerFonts;