import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/CreateCVTemplate.css';

function CreateCVTemplate() {
    const [templateName, setTemplateName] = useState('');
    const [primaryColors, setPrimaryColors] = useState([{ id: 1, hex: '#000080' }]);
    const [secondaryColors, setSecondaryColors] = useState([{ id: 1, hex: '#000000' }]);
    const [tertiaryColors, setTertiaryColors] = useState([]);
    const [selectedFont, setSelectedFont] = useState('');
    const [cvCSS, setCVCSS] = useState('{}');
    const [fonts, setFonts] = useState([]);
    const [fontSize, setFontSize] = useState(12);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/getFonts`)
            .then(response => setFonts(response.data))
            .catch(error => console.error('Error fetching fonts:', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/lagCVMal`, {
                name: templateName,
                primaryColors: primaryColors,
                secondaryColors: secondaryColors,
                tertiaryColors: tertiaryColors,
                fonts_idfonts: selectedFont,
                cv_css: cvCSS
            });
            alert('Template created successfully!');
            window.location.reload();
            // Reset form or show success message
        } catch (error) {
            console.error('Error creating template:', error);
            // Show error message
        }
    };

    const addColor = (setColorFunction) => {
        setColorFunction(prevColors => {
            const newId = prevColors.length > 0 ? Math.max(...prevColors.map(c => c.id)) + 1 : 1;
            return [...prevColors, { id: newId, hex: '#000000' }];
        });
    };

    const updateColor = (id, value, setColorFunction) => {
        setColorFunction(prevColors => 
            prevColors.map(color => color.id === id ? { ...color, hex: value } : color)
        );
    };



    const handleFontSizeChange = (e) => {
        setFontSize(e.target.value);
    };

    const removeColor = (id, setColorFunction) => {
        setColorFunction(prevColors => prevColors.filter(color => color.id !== id));
    };

    const renderColorInputs = (colors, setColorFunction, label) => (
        <div className="colorSection">
            <label>{label}:</label>
            {colors.map((color) => (
                <div key={color.id}>
                    <input
                        type="color"
                        value={color.hex}
                        onChange={(e) => updateColor(color.id, e.target.value, setColorFunction)}
                    />
                    <input
                        type="text"
                        value={color.hex}
                        onChange={(e) => updateColor(color.id, e.target.value, setColorFunction)}
                        maxLength="7"
                        style={{width: '70px', marginLeft: '5px'}}
                    />
                    <button type="button" onClick={() => removeColor(color.id, setColorFunction)}>Remove</button>
                </div>
            ))}
            <button type="button" onClick={() => addColor(setColorFunction)}>Add Color</button>
        </div>
    );

    return (
        <div className="create-cv-template">
            <h1>Create CV Template</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="templateName">Template Name:</label>
                    <input
                        type="text"
                        id="templateName"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        required
                    />
                </div>

                {renderColorInputs(primaryColors, setPrimaryColors, "Primary Colors")}
                {renderColorInputs(secondaryColors, setSecondaryColors, "Secondary Colors")}
                {renderColorInputs(tertiaryColors, setTertiaryColors, "Tertiary Colors")}
                <div>
                    <label htmlFor="fontSize">Font Size:</label>
                    <input
                        type="number"
                        id="fontSize"
                        value={fontSize}
                        onChange={(e) => setFontSize(e.target.value)}
                        required
                    />
                </div>

                <div>
                    <label htmlFor="font">Font:</label>
                    <select
                        id="font"
                        value={selectedFont}
                        onChange={(e) => setSelectedFont(e.target.value)}
                        required
                    >
                        <option value="">Select a font</option>
                        {fonts.map(font => (
                            <option key={font.idfonts} value={font.idfonts}>{font.fontName}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="cvCSS">CV CSS (JSON format):</label>
                    <textarea
                        id="cvCSS"
                        value={cvCSS}
                        onChange={(e) => setCVCSS(e.target.value)}
                        rows="5"
                    />
                </div>

                <button type="submit">Create Template</button>
            </form>
        </div>
    );
}

export default CreateCVTemplate;