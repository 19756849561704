import React, { useState, useRef, useEffect } from 'react';
import '../css/CustomSlider.css';
import saveData from './saveData';
import updateCV from './updateCV';
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from '../hooks/useLocalStorage';
const CustomSlider = ({cvSize}) => {
  const { isAuthenticated } = useAuth();
  const [serverLastUpdated, setServerLastUpdated] = useState(null);
  const [value, setValue] = useState(cvSize || 10);
  const [isDragging, setIsDragging] = useState(false);
  const [storedData, setStoredData] = useLocalStorage('fontSettings', {});
  const sliderRef = useRef(null);

  const MIN = 8;
  const MAX = 14;
  const STEP = 1;

  const handleMouseDown = (e) => {
    setIsDragging(true);
    updateValue(e);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      updateValue(e);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    saveFontSize(value);
  };

  const updateValue = (e) => {
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percent = x / rect.width;
    const rawValue = percent * (MAX - MIN) + MIN;
    const steppedValue = Math.round(rawValue / STEP) * STEP;
    const newValue = Math.max(MIN, Math.min(MAX, steppedValue));
    setValue(newValue);
    saveFontSize(newValue);
  };

  const saveFontSize = (newValue) => {
    const data = {
      fontSize: newValue,
      lastUpdated: new Date().toISOString()
    };
    setStoredData(data);
    // localStorage.setItem('fontSettings', JSON.stringify(data));
    
    // let idcv = window.location.search.split('=')[1];
    // if(idcv) {
    //   updateCV({ fontSize: newValue }, idcv);
    //   syncWithServer(data);
    // } else {
    //   saveData({ fontSize: newValue });  
    // }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseu p', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    if (isAuthenticated) {
        if(!storedData) {
        // if(!localStorage.getItem('fontSettings')) {
        fetchFontSettings();
      }

      const interval = setInterval(() => {
        const localData = JSON.parse(localStorage.getItem('fontSettings'));
        const localLastUpdated = localData?.lastUpdated;
        
        if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
          syncWithServer(localData);
        }
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, serverLastUpdated]);

  const syncWithServer = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/cv/fontSizes`,
        { 
          ...data,
          fontSize: value,
          cvId: localStorage.getItem('cvId')
        },
        { withCredentials: true }
      );
      if(response.status === 200) {
        setServerLastUpdated(response.data.lastUpdated);
      }
    } catch (error) {
      console.error('Error syncing with server:', error);
    }
  };

  const fetchFontSettings = async () => {
    let token = window.location.search.split('=')[0];
    let idcv = window.location.search.split('=')[1];
    let cvId = localStorage.getItem('cvId');

    if((cvId && idcv && (cvId === idcv)) || idcv && token === "?idcv") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveFonts`,
          {
            withCredentials: true,
            params: { cvId: idcv }
          }
        );
        if (response.data.fontSize) {
          setValue(response.data.fontSize);
          setStoredData({
            fontSize: response.data.fontSize,
            lastUpdated: response.data.lastUpdated
          });
        }
        setServerLastUpdated(response.data.lastUpdated);
      } catch (error) {
        console.error('Error fetching font settings:', error);
      }
    }
  };

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${((value - MIN) * 100) / (MAX - MIN)}% 100%`
    };
  };

  return (
    <div className="slider-container2">
      <h2>Font størrelse</h2>
      <input
        type="range"
        min={MIN}
        max={MAX}
        step={STEP}
        value={value}
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onChange={(e) => {
          const newValue = Number(e.target.value);
          setValue(newValue);
          saveFontSize(newValue);
        }}
        className="slider"
        style={{
          ...getBackgroundSize(),
          background: `linear-gradient(to right, #FB8500 0%, #FB8500 ${((value - MIN) * 100) / (MAX - MIN)}%, hsla(32, 100%, 49%, 0.172) ${((value - MIN) * 100) / (MAX - MIN)}%, hsla(32, 100%, 49%, 0.172) 100%)`,
        }}
      />
      <div className="textRow">
        <span>aA</span>
        <span>A</span>
      </div>
      <div className="selected-value">
        Basis font størrelse: {value}
      </div>
    </div>
  );
};

export default CustomSlider;
