import Input from "../components/Input";
import { useState, useEffect } from "react";
import axios from "axios";
import saveData from "./saveData";
import updateCV from "./updateCV";
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from '../hooks/useLocalStorage';



export default function PersonaliaBox({ handleSubmit }) {

    const { user, isAuthenticated } = useAuth();
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const [personalia, setPersonalia] = useLocalStorage('personalia', {});
    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem('personalia');
        return savedData ? {
            ...JSON.parse(savedData)
        } : {
            firstName: '',
            lastName: '',
            phonenumber: '',
            email: '',
            date: '',
            jobtitle: '',
            profilePicture: '',
            address: '',
            zipcode: '',
            city: '',
            country: '',
            licence: '',
            nationality: '',
        };
    });

    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            if(!localStorage.getItem('personalia')) {
                fetchPersonalia();
            }

            // Set up interval to check for updates
            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem('personalia'));
                const localLastUpdated = localData?.lastUpdated;
                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000); // Check every 10 seconds

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);

    const syncWithServer = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/personalia`,
                { ...data, cvId: localStorage.getItem('cvId') },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };

    const fetchPersonalia = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        //if localstorage and browser has id, and they are the same, fetch latest data from server
        if((cvId && idcv && (cvId === idcv))|| idcv && token === "?idcv" ) {
            console.log("fetching latest data from server, it exists both in localstorage and browser");
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {

                const localData = JSON.parse(localStorage.getItem('personalia'));
                if (!localData) {
                    // setPersonaliaValues('personalia', response.data);
                    //localStorage.setItem('personalia', JSON.stringify(response.data));
                    setPersonalia(response.data);
                }

                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if (!idcv && cvId) {
            console.log("fetching latest data from server, it exists only in localstorage");
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId}
            }).then((response) => {
                const localData = JSON.parse(localStorage.getItem('personalia'));
                if (!localData) {
                    // setPersonaliaValues('personalia', response.data);
                    // localStorage.setItem('personalia', JSON.stringify(response.data));
                    setPersonalia(response.data);
                }
                setServerLastUpdated(response.data.lastUpdated);
            });
        } else if ((idcv && token === "?idcv") && cvId !== idcv) {
            console.log("fetching latest data from server, browser shows a different id than localstorage, need to update localstorage");
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/cv/retrievePersonalia`, {
                withCredentials: true,
                params: {cvId: idcv}
            }).then((response) => {
                // setPersonaliaValues('personalia', response.data);           
                // localStorage.setItem('personalia', JSON.stringify(response.data));
                setPersonalia(response.data);
                setServerLastUpdated(response.data.lastUpdated);
            });
        }
    }
/*
    const fetchLatestCVData = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];


        if(idcv && token === "?idcv") {
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch-cv-id`, {idcv: idcv}, {
                withCredentials: true
            }).then((response) => {

                setFormData(prevData => ({
                    ...prevData,
                    ...response.data[0].cvContent.Personalia
                }));
            })
            
        } else {

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch-cv`, {}, {
                withCredentials: true
            });
            if (response.data && response.data.cvContent.Personalia) {
                setFormData(prevData => ({
                    ...prevData,
                    ...response.data.cvContent.Personalia
                }));
            }

            if (response.data.profilePicture) {
                setFormData(prevData => ({
                    ...prevData,
                    profilePicture: response.data.profilePicture
                }));
      
            }
        } catch (error) {
            console.error('Error fetching CV data:', error);
        }
    }

    };
*/
    const showMoreFunc = () => {
        setShowMore(!showMore);
    };

    const handleLocalInputChange = async (field, value) => {
        const lastUpdated = new Date().toISOString();
        const newData = { ...formData, [field]: value, lastUpdated };
        // setStorageValue('personalia', newData); 
        // localStorage.setItem('personalia', JSON.stringify(newData));
        setFormData(newData);
        setPersonalia(newData);
        
    };


    const handleFileUpload = (e) => {

        let file = e.target.files[0];
        const fileData = new FormData();
        fileData.append('image', file);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/upload/profilePicture`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }, withCredentials: true
        })
        .then(response => {
            // Håndter vellykket opplasting her
        })
        .catch(error => {
            console.error('Feil ved opplasting av fil:', error);
            // Håndter feil her
        });
    }

    return (
        <div className="personaliaBox">
            <div className="personaliaBoxPad">
                <h2>Personalia</h2>
                <p>Her fyller du ut generell informasjon om deg selv</p>
                <div className="inputs">
                    <Input 
                        placeholder="fornavn" 
                        value={formData.firstName} 
                        onChange={(value) => handleLocalInputChange("firstName", value)} 
                    />
                    <Input 
                        placeholder="etternavn" 
                        value={formData.lastName} 
                        onChange={(value) => handleLocalInputChange("lastName", value)} 
                    />
                    <Input 
                        placeholder="telefonnummer" 
                        value={formData.phonenumber} 
                        onChange={(value) => handleLocalInputChange("phonenumber", value)} 
                    />
                    <Input 
                        placeholder="epost" 
                        value={formData.email} 
                        onChange={(value) => handleLocalInputChange("email", value)} 
                    />
                    <Input 
                        placeholder="stillingstittel" 
                        value={formData.jobtitle} 
                        onChange={(value) => handleLocalInputChange("jobtitle", value)} 
                    />
                </div>
                {formData.profilePicture ? 
                    <div>
                        <img width="100"  src={formData.profilePicture} alt={formData.profilePicture} />
                        <span onClick={() => setFormData(prev => ({...prev, profilePicture: ''}))}>x</span>
                    </div> 
                    : 
                    <input type="file" onChange={(e) => {handleFileUpload(e); setFormData(prev => ({...prev, profilePicture: e.target.files[0].name}))}} name={formData.profilePicture || "Last opp profilbilde"} />
                }
                {/* <CTA2 onClick={handleSubmit} onFileUpload={handleFileUpload} name={formData.profilePicture || "Last opp profilbilde"} /> */}
                <div className="moreButton" onClick={showMoreFunc}>
                    {!showMore ? <p>Vis flere felt</p> : <p>Skjul flere felt</p>}
                </div>
                {showMore && (
                    <div className="inputs">
                        <Input 
                            placeholder="fødselsdato" 
                            value={formData.date} 
                            onChange={(value) => handleLocalInputChange("date", value)} 
                        />
                        <Input 
                            placeholder="addresse" 
                            value={formData.address} 
                            onChange={(value) => handleLocalInputChange("address", value)} 
                        />
                        <Input 
                            placeholder="postnummer" 
                            value={formData.zipcode} 
                            onChange={(value) => handleLocalInputChange("zipcode", value)} 
                        />
                        <Input 
                            placeholder="by" 
                            value={formData.city} 
                            onChange={(value) => handleLocalInputChange("city", value)} 
                        />
                        <Input 
                            placeholder="land" 
                            value={formData.country} 
                            onChange={(value) => handleLocalInputChange("country", value)} 
                        />
                        <Input 
                            placeholder="førerkort" 
                            value={formData.licence} 
                            onChange={(value) => handleLocalInputChange("licence", value)} 
                        />
                        <Input 
                            placeholder="nasjonalitet" 
                            value={formData.nationality} 
                            onChange={(value) => handleLocalInputChange("nationality", value)} 
                        />
                    </div>
                )}
            </div>
        </div>
    );
}