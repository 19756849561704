import "../../css/buttons/CTA.css"
import { Link } from "react-router-dom";
import { useState } from 'react';

export default function CTA2({ name, path, onClick, onKeyDown, onFileUpload }) {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0 && onFileUpload) {
            onFileUpload(files[0]);
        }
    };

    return (
        <div 
            className={`cta2 ${isDragging ? 'dragging' : ''}`}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {name}
            <input 
                type="file" 
                style={{ display: 'none' }} 
                onChange={(e) => onFileUpload && onFileUpload(e.target.files[0])}
            />
        </div>
    );
}