import { ReactComponent as ArrowLeft } from "../img/arrowLeft.svg"
import { ReactComponent as ArrowRight } from "../img/arrowRight.svg"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios";
import getCVMal from "./getCVMal.js"

export default function TemplatesList() {
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [cvTemplates, setCvTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const navigate = useNavigate();
    const handleTemplateClick = (template) => {

      axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/edit-cv`, {
        idcvMal: template.idcvMal
    }, {withCredentials: true} ).then((response) => {

      console.log(response);

        setIsTransitioning(true);
            setTimeout(() => {
                setSelectedTemplate(template);
                navigate("/lagecv", { state: { template: template, isTransitioning: isTransitioning } });
                setIsTransitioning(false);
            }, 500); // 500ms tr
    
      });
      };
      const nextTemplates = () => {
        setCurrentIndex((prev) => (prev + 1) % cvTemplates.length);
      };
    
      const prevTemplates = () => {
        setCurrentIndex((prev) => (prev - 1 + cvTemplates.length) % cvTemplates.length);
      };    


      useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "ArrowLeft") {
              prevTemplates();
            } else if (event.key === "ArrowRight") {
              nextTemplates();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [cvTemplates.length]); 

      useEffect(() => {
        if (cvTemplates.length > 0) {
          updateVisibleTemplates();
        }
      }, [cvTemplates, currentIndex]);
    
      const updateVisibleTemplates = () => {
        const templates = [];
        for (let i = 0; i < 3; i++) {
          const index = (currentIndex + i) % cvTemplates.length;
          templates.push(cvTemplates[index]);
        }
        setVisibleTemplates(templates);
      };
      useEffect(() => {
        getCVMal({setCvTemplates});
      }, []);


  return (
    <section className="template-slider" onKeyDown={(e) => {
      console.log(e.key, "KEY");
                if (e.key === "ArrowLeft") {
                   prevTemplates();
                }
                if (e.key === "ArrowRight") {
                   nextTemplates();
                }
            }}>
    {/* <h2>Våre CV-maler</h2> */}
    <div className="slider-container" >
        <ArrowLeft className="arrowLeft" onClick={prevTemplates}></ArrowLeft>
      {/* <button onClick={prevTemplates} className="slider-button">&lt;</button> */}
      <div className="templates-wrapper">
        {visibleTemplates.map((template) => (
          <div key={template.id} className="template-preview" onClick={() => handleTemplateClick(template)}>
            <img className="cvTemplateImages" src={process.env.REACT_APP_FRONTEND_URL+`${template.previewImage}`} alt={template.name}></img>
            {/* <img  class="cvTemplateImages" src={process.env.REACT_APP_FRONTEND_URL+"/cvs/Cali.png"} alt={template.name}></img> */}
            {/* <div className="template-image">Template Preview Placeholder</div> */}
            <h3>{template.name}</h3>
          </div>
        ))}
      </div>
      {/* <button onClick={nextTemplates} className="slider-button">&gt;</button> */}
      <ArrowRight className="arrowRight" onClick={nextTemplates}></ArrowRight>
    </div>
  </section>
  );
}
