import React from 'react';
import { useState } from "react";
import { ReactComponent as Toggle1 } from '../img/Toggle1.svg';
import { ReactComponent as Toggle2 } from '../img/Toggle2.svg';

const InputWithToggle = ({
  placeholder,
  name,
  onChange,
  onClick,
  onBlur,
  type,
  activateToggle,
  setActivateToggle
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(value);
  };

  return (
    <div className="input-wrapper">
      <input
        placeholder={activateToggle ? "Nåværende jobb" : placeholder}
        name={name}
        value={activateToggle ? "Nåværende jobb" : inputValue}
        onChange={handleInputChange}
        type={type}
        onBlur={onBlur}
        className="input-field"
      />
      <div className="toggle" onClick={() => {
        const newToggleState = !activateToggle;
        setActivateToggle(newToggleState);
        if (newToggleState) {
          onChange("Nåværende jobb");
        } else {
          onChange(inputValue);
        }
      }}>
        {activateToggle ? <Toggle2 /> : <Toggle1 />}
      </div>
    </div>
  );
};

export default InputWithToggle;
