import CVAccordion from "../components/CVAccordion"

export default function Faq() {
  return (
    <div>
      <h1>FAQ</h1>
      <CVAccordion />
    </div>
  );
}
