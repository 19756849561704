import "../css/Input.css"
import Input from "../components/Input"
import CTA2 from "../components/buttons/CTA2"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import handleSubmission from "../components/HandleSubmission"
import handleInputChange from "../components/HandleInputChange"
import { useAuth } from "../components/AuthContext"
export default function Login({redirect, msg}) {

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [errorMsg, setErrorMsg] = useState({
        isErrorMsg: false,
        errorMsg: ''
    });

    const { isAuthenticated, setIsAuthenticated, checkLogin } = useAuth();
    const [shouldRedirect, setShouldRedirect] = useState(true);

    useEffect(() => {
    }, [redirect])




    return (
        <div className="login">
            <h1>Logg inn</h1>
            {msg ? <p>{msg}</p> : <div></div>}
            <div onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleSubmission(formData, "login", setErrorMsg, shouldRedirect, setIsAuthenticated)
                }
            }}>

                <form>
                    <Input type="text" placeholder="email" name={formData.email} onChange={(value) => handleInputChange("email", value, setFormData)}></Input>
                    <Input type="password" placeholder="password" name={formData.password} onChange={(value) => handleInputChange("password", value, setFormData)}></Input>
                </form>
                <Link to="/nytt-passord" className="url">Glemt passord?</Link>
                {/* <CTA2 name="logg inn"></CTA2> */}
                <CTA2 name="logg inn" path={redirect ? "" : "/profile"} onClick={() => handleSubmission(formData, "login", setErrorMsg, shouldRedirect, setIsAuthenticated)}></CTA2>
                <Link to="/register" className="url">Ny bruker?</Link>
                {errorMsg.isErrorMsg ? <p>{errorMsg.errorMsg}</p> : <div></div>}
            </div>
        </div>
    )
}