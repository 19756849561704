import { useState, useEffect } from "react";
import axios from "axios";
import saveData from "./saveData";
import updateCV from "./updateCV";
import '../css/Design.css';
import {ReactComponent as CheckmarkIcon} from '../img/checkmark.svg';
import { useAuth } from "../components/AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";


export default function ColorPicker({primaryColors, secondaryColors, tertiaryColors}) {
    const { isAuthenticated } = useAuth();
    const [serverLastUpdated, setServerLastUpdated] = useState(null);
    const [colorFormData, setColorFormData] = useLocalStorage('colorFormData', {});
    const [formData, setFormData] = useState(() => {
        const savedData = localStorage.getItem('colorFormData');
        return savedData ? JSON.parse(savedData) : {
            primaryColor: '',
            secondaryColor: '',
            tertiaryColor: '',
        };
    });

    useEffect(() => {
        if (isAuthenticated) {
            if(!localStorage.getItem('colorFormData')) {
                fetchColors();
            }

            // Set up interval to check for updates
            const interval = setInterval(() => {
                const localData = JSON.parse(localStorage.getItem('colorFormData'));
                const localLastUpdated = localData?.lastUpdated;
                
                if (localLastUpdated && (!serverLastUpdated || new Date(localLastUpdated) > new Date(serverLastUpdated))) {
                    syncWithServer(localData);
                }
            }, 10000); // Check every 10 seconds

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, serverLastUpdated]);

    const syncWithServer = async (data) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/cv/colors`,
                { ...data, cvId: localStorage.getItem('cvId') },
                { withCredentials: true }
            );
            if(response.status === 200) {
                setServerLastUpdated(response.data.lastUpdated);
            }
        } catch (error) {
            console.error('Error syncing with server:', error);
        }
    };

    const fetchColors = async () => {
        let token = window.location.search.split('=')[0];
        let idcv = window.location.search.split('=')[1];
        let cvId = localStorage.getItem('cvId');

        if((cvId && idcv && (cvId === idcv)) || idcv && token === "?idcv") {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/cv/retrieveColors`,
                    { withCredentials: true }
                );
                if(response.status === 200) {
                    setServerLastUpdated(response.data.lastUpdated);
                }
            } catch (error) {
                console.error('Error fetching colors:', error);
            }
        }
    };

    const handleColorChange = (colorName, color) => {
        const updatedFormData = { ...formData, [colorName]: color };
        console.log(updatedFormData, "updatedFormData");

        const lastUpdated = new Date().toISOString();
        const updatedFormDataWithLastUpdated = { ...updatedFormData, lastUpdated };
        setColorFormData(updatedFormDataWithLastUpdated);
        // setColorValues('colorFormData', updatedFormDataWithLastUpdated);
        //localStorage.setItem('colorFormData', JSON.stringify(updatedFormDataWithLastUpdated));
        setFormData(updatedFormDataWithLastUpdated);


        let idcv = window.location.search.split('=')[1];
        // if(idcv) {
        //     updateCV({ farger: updatedFormData }, idcv);
        // } else {
        //     saveData({ farger: updatedFormData });
        // }
    }

    const getColorClassName = (colorType, color) => {
        return `${colorType} ${formData[colorType] === color ? 'clicked' : ''}`;
    }

    // if(selectedTemplate.length > 0){
        
        return (
            <div>
            <h2>Velg farger</h2>
            <div className="primary-colors">
                <h4>Primærfarge</h4>
                <div className="colorBox">
                    {primaryColors.map((color, index) => {
                        return(
                            <div 
                            onClick={() => handleColorChange("primaryColor", color.hex)} 
                            key={index} 
                            className={getColorClassName("primaryColor", color.hex)} 
                            style={{ backgroundColor: color.hex }}
                            >
                                {formData.primaryColor === color.hex && <CheckmarkIcon className="checkmark" />}
                            </div>
                        )
                        // <input key={index} type="color" value={color} />
                    }
                    )}
                </div>
            </div>
            <div className="secondary-colors">
                <h4>Sekundærfarge</h4>
                <div className="colorBox">
                    {secondaryColors.map((color, index) => (
                        <div onClick={() => handleColorChange("secondaryColor", color.hex)} key={index} className={getColorClassName("secondaryColor", color.hex)} style={{ backgroundColor: color.hex }}>
                            {formData.secondaryColor === color.hex && <CheckmarkIcon className="checkmark" />}
                        </div>
                    ))}
                </div>
            </div>
            <div className="tertiary-colors">
                <h4>Tetriærfarge</h4>
                <div className="colorBox">

                    {tertiaryColors.map((color, index) => (
                        <div onClick={() => handleColorChange("tertiaryColor", color.hex)} key={index} className={getColorClassName("tertiaryColor", color.hex)}  style={{ backgroundColor: color.hex }}>   {formData.tertiaryColor === color.hex && <CheckmarkIcon className="checkmark" />}</div>
                    ))}
                </div>
            </div>
        </div>
        )
    // }
}   
