import React from "react";
import { ReactComponent as TrashIconSVG } from "../img/Delete.svg";
import { useLocalStorage } from '../hooks/useLocalStorage';
const TrashIcon = ({ containers, setContainers, name, index }) => {
  const [storedData, setStoredData] = useLocalStorage(name.toLowerCase(), {});
  const handleDelete = () => {
    // Remove the container at the specified index
    const updatedContainers = containers.filter((_, i) => i !== index);
    setContainers(updatedContainers);

    // Update localStorage
    const localStorageKey = name.toLowerCase();
    const dataToStore = {
      [name]: updatedContainers,
      lastUpdated: new Date().toISOString(),
    };
    setStoredData(dataToStore);
    // localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
  };

  return (
    <div className="trash-icon" onClick={handleDelete}>
      <TrashIconSVG />
    </div>
  );
};

export default TrashIcon;