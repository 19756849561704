import { Link } from "react-router-dom";
import "../css/LandingPage.css";
export default function Prices({ isMalChosen }) {
  return (
    <section className="pricing-section">
      <h2>Velg din plan</h2>
      <div className="pricing-container">
        <div className="pricing-box free">
          <h3>Basis</h3>
          <h2>Gratis</h2>
          <p>Test ut plattformen vår uten å betale.</p>
 
            <div class="cta-container">
              <Link to="/velgeMal" className="cta-button freeCTA">
                Velg gratis
              </Link>
            </div>

          <ul>
            <li>✓ Tilgang til alle CV-maler</li>
            <li>✓ Lag og redigering av én CV</li>
            <li>✓ Direkte forhåndsvisning av dine dokumenter</li>
            <li>✓ Arbeidet er lagret i 2 dager, også uten konto</li>
            <li>✓ AI-assistert innhold</li>
            <li>✗ Last ned CVene</li>
            <li>✗ Mulighet til å skrive søknader</li>
            <li>✗ Avanserte tilpasninger</li>
            <li>✗ Prioritert support</li>
          </ul>
        </div>
        <div className="pricing-box pro">
          <h3>Pro</h3>
          <h2>249 kr/mnd</h2>
          <p>Test ut med en 7 dagers gratis prøveperiode for 9 kroner.</p>
    
            <div class="cta-container proCTA">
              <Link to="/velgeMal" className="cta-button">
                Velg pro
              </Link>
            </div>

          <ul>
            <li>✓ Tilgang til alle CV-maler</li>
            <li>✓ Lage ubegrenset antall CV-maler</li>
            <li>✓ Laste ned alle CVer</li>
            <li>✓ Lage søknader</li>
            <li>✓ AI-assistert innholdsskriving</li>
            <li>✓ Mulighet til å skrive søknader</li>
            <li>✓ Bruke Vipps til betaling</li>
            <li>✓ Avanserte tilpasninger</li>
            <li>✓ Prioritert support</li>
            <li>✓ Abonnementet kan avsluttes når som helst</li>
          </ul>

          {/* <Link to="/lagecv" className="cta-button">Velg Pro</Link> */}
        </div>
      </div>
    </section>
  );
}
