import React, { useState } from 'react';
import '../css/CVAccordion.css';

const accordionData = [
  {
    title: 'Hva er en CV?',
    content: 'En CV (Curriculum Vitae) er et dokument som oppsummerer din utdanning, arbeidserfaring, ferdigheter og prestasjoner. Det brukes for å presentere deg selv for potensielle arbeidsgivere.'
  },
  {
    title: 'Hvordan skrive en bra CV?',
    content: 'En bra CV bør være klar, konsis og relevant. Fremhev dine viktigste prestasjoner, bruk nøkkelord fra jobbannonsen, og tilpass CVen til hver stilling du søker på.'
  },
  {
    title: 'Hva er forskjellen mellom en CV og et resymé?',
    content: 'En CV er vanligvis lengre og mer detaljert, mens et resymé er en kort oppsummering (1-2 sider) av dine viktigste kvalifikasjoner og erfaringer.'
  },
  {
    title: 'Hvordan begynner jeg å skrive CVen min?',
    content: 'Start med å samle all relevant informasjon om din utdanning, arbeidserfaring og ferdigheter. Lag deretter en oversiktlig struktur med klare overskrifter og punktlister.'
  },
  {
    title: 'Hva bør jeg inkludere på CVen min?',
    content: 'Inkluder personlig informasjon, utdanning, arbeidserfaring, ferdigheter, og eventuelle relevante prestasjoner eller frivillig arbeid. Tilpass innholdet til stillingen du søker.'
  },
  {
    title: 'Hva er en kronologisk CV og i hvilken rekkefølge skal den oppføres?',
    content: 'En kronologisk CV lister opp dine erfaringer i omvendt kronologisk rekkefølge, med den nyeste erfaringen først. Dette formatet er mest vanlig og lett å lese for arbeidsgivere.'
  },
  {
    title: 'Hvordan lage din egen CV (eller følgebrev)',
    content: 'Bruk en profesjonell mal eller et CV-byggerverktøy. Fokuser på å presentere din informasjon klart og konsist. For følgebrev, forklar hvorfor du er interessert i stillingen og hvordan dine ferdigheter matcher kravene.'
  },
  {
    title: 'Tips for å lage den perfekte CVen',
    content: 'Hold det kort og relevant, bruk aktive verb, kvantifiser dine prestasjoner når mulig, tilpass til hver jobb, og sørg for at det er feilfritt og profesjonelt formatert.'
  },
  {
    title: 'Trenger du en god CV?',
    content: 'Ja, en god CV er avgjørende for å skille seg ut i jobbsøkingsprosessen. Det er ofte din første mulighet til å gjøre et godt inntrykk på en potensiell arbeidsgiver.'
  }
];

function CVAccordion() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="cv-accordion">
      {/* <h2>Informasjon</h2> */}
      {accordionData.map((item, index) => (
        <div className="accordion-item" key={index}>
          <button
            className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            {item.title}
          </button>
          <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
            <div className="accordion-content-inner">{item.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CVAccordion;
