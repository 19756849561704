import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Application.css';

export default function Application() {
  const [jobDescription, setJobDescription] = useState('');
  const [selectedCV, setSelectedCV] = useState('');
  const [cvList, setCVList] = useState([]);
  const [generatedApplication, setGeneratedApplication] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    fetchCVs();
  }, []);

  const fetchCVs = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetchCVs`, {}, {withCredentials: true});
      setCVList(response.data);
    } catch (error) {
      console.error('Error fetching CVs:', error);
    }
  };

  const handleGenerateApplication = async () => {
    setIsGenerating(true);
    setGeneratedApplication('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generate-application`, {
        jobDescription,
        selectedCV,
        additionalInfo,
      }, {withCredentials: true});
      setGeneratedApplication(response.data.application);
      setIsEditing(false);
    } catch (error) {
      console.error('Error generating application:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleApplicationChange = (e) => {
    setGeneratedApplication(e.target.value);
  };
  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([generatedApplication.split('---')[0]], {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = "søknad.doc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element); 
  };

  return (
    <div className="application-container">
      <h1>Lag jobbsøknad</h1>
      
      <section className="input-section">
        <div className="job-description">
          <h2>Jobb beskrivelse</h2>
          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Lim inn jobbeskrivelsen her..."
            rows={10}
          />
        </div>
        
        <div className="cv-selection">
          <h2>Velg CV</h2>
          {cvList.length > 0 ? (
            <select
              value={selectedCV}
              onChange={(e) => setSelectedCV(e.target.value)}
            >
              <option value="">Velg en CV</option>
              {cvList.map((cv, index) => (
                <option key={index} value={cv.idcv}>
                  {cv.last_cv_update}
                </option>
              ))}
            </select>
          ) : (
            <div className="no-cv-message">
              <p>Ingen CVer funnet. Vennligst lag en CV først.</p>
              <a href="/velgeMal">Gå til CV Lager</a>
            </div>
          )}
        </div>

        <div className="additional-info">
          <h2>Tilleggsinformasjon</h2>
          <textarea
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder="Legg til ekstra informasjon som er nødvendig for å lage søknaden..."
            rows={5}
          />
        </div>
        
        <button 
          onClick={handleGenerateApplication} 
          disabled={isGenerating}
          className={isGenerating ? 'generating' : ''}
        >
          {isGenerating ? 'Genererer søknad...' : 'Lag en søknad'}
        </button>
      </section>
      
      <section className="output-section">
        <h2>Generert søknad</h2>
        <div className="generated-application">
          {isGenerating ? (
            <p className="generating-message">Genererer søknad... Vennligst vent.</p>
          ) : generatedApplication ? (
            <>
              {isEditing ? (
                <textarea
                  value={generatedApplication}
                  onChange={handleApplicationChange}
                  rows={15}
                  className="edit-application-textarea"
                />
              ) : (
                <>
                  <div className="application-text">
                    <pre>{generatedApplication.split('---')[0]}</pre>
                  </div>
                  <div className="application-metadata">
                    <pre>{generatedApplication.split('---')[1]}</pre>
                  </div>
                </>
              )}
              <div className="button-container">
                <button onClick={handleEditToggle} className="edit-toggle-button">
                  {isEditing ? 'Lagre endringer' : 'Rediger søknad'}
                </button>
                <button onClick={handleDownload} className="download-button">
                  Last ned søknad
                </button>
              </div>
            </>
          ) : (
            <p>Din genererte søknad vil dukke opp her.</p>
          )}
        </div>
      </section>
    </div>
  );
}
