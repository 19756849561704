import { useState, useEffect } from "react";
import CTA3 from "./buttons/CTA3.js";

export default function AddMoreSections({addBox, displayedComponents}) {
    const [seksjoner, setSeksjoner] = useState(['Språk', 'Kurs', 'Interesser', 'Frivillighet', 'Militærtjeneste', 'Portefølje']);
    const [synligeSeksjoner, setSynligeSeksjoner] = useState([]);

    // Filter out sections that are already displayed
    useEffect(() => {
        const filteredSections = seksjoner.filter(seksjon => {
            const lowerCaseName = seksjon.toLowerCase();
            return !displayedComponents[lowerCaseName];
        });
        setSynligeSeksjoner(filteredSections);
    }, [displayedComponents]);

    // Only render if there are sections to show
    if (synligeSeksjoner.length === 0) {
        return null;
    }

    return (
        <div className="addMoreSections">
            <h2>LEGG TIL FLERE SEKSJONER</h2>
            <div className="buttons" style={{justifyContent: window.innerWidth > 600 && synligeSeksjoner.length % 3 === 0 ? 'space-evenly' : 'flex-start'}}>
                {synligeSeksjoner.map((seksjon) => (
                    <CTA3 
                        key={seksjon}
                        name={seksjon} 
                        onClick={() => {
                            addBox(seksjon.toLowerCase());
                            setSynligeSeksjoner(prev => prev.filter(s => s !== seksjon));
                        }}
                    />
                ))}
            </div>
        </div>
    );
}