import "../../css/buttons/CTA.css"
import { Link } from "react-router-dom";

export default function CTA3({ name, path, onClick, onKeyDown, isMalChosen }) {
    if (path) {
        return (
            <Link to={path}>
                <div className="cta3" onClick={onClick} onKeyDown={onKeyDown}>
                    {name}
                </div>
            </Link>
        );
    } else {
        return (
            <div className="cta3" onClick={onClick} onKeyDown={onKeyDown}>
                {name}
            </div>
        );
    }
}