import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { AuthProvider } from './components/AuthContext';

const root = ReactDOM.createRoot(document.getElementById("root"));
const path = window.location.pathname;
root.render(
  // <React>
  // <React.StrictMode>
          <AuthProvider>

    <BrowserRouter>
    {path !== "/lagecv" ? <Navbar></Navbar> : null}
      <App />
      {path !== "/lagecv" ? <Footer></Footer> : null}
    </BrowserRouter>
          </AuthProvider>
  // {/* </React> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
