export default function Input({ name, placeholder, onChange, type, onBlur, value }) {
    return (
        <input 
            placeholder={placeholder} 
            name={name} 
            onChange={(e) => onChange(e.target.value)} 
            type={type} 
            onBlur={onBlur}
            value={value}
        >
        </input>
    )
}