import "./App.css";
import "./css/Navbar.css";

import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Notfound from "./pages/NotFound"
import LandingPage from "./pages/LandingPage"
import Registrer from "./pages/Register"
import Login from "./pages/Login"
import ResetPassword from "./pages/ResetPassword"
import ResetPasswordForm from "./components/ResetPasswordForm"
import Profile from "./pages/Profile"
import Blogg from "./pages/Blogg";
import Salesterms from "./pages/Salesterms";
import Privacy from "./pages/Privacy";
import Prices from "./pages/Prices";
import Templates from "./pages/Templates";
import About from "./pages/About";
import FAQ from "./pages/Faq";
import Contact from "./pages/ContactUs";
import CreateCVTemplate from "./pages/CreateCVTemplate";
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import CVCreater from "./pages/CvCreater";
import ChooseTemplate from "./pages/ChooseTemplate";
import Application from "./pages/Application";
import Agreements from "./pages/Agreements";
import CTAS from "./pages/CTAS";
function App() {
  const location = useLocation();
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {

    // if(!isFetched){
      // setIsFetched(true);
      // createAnonymousToken(location);
    // }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/lagecv" exact element={<CVCreater />} />
        <Route path="/velgeMal" exact element={<ChooseTemplate />} />
        <Route path="/register" exact element={<Registrer />} />
        <Route path="/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>}>
        {/* <Profile></Profile> */}
          {/* <Route path="/profile" element={<Profile />} /> */}
          {/* Add more protected routes here */}
        </Route>
        <Route path="/application" element={<ProtectedRoute><Application/></ProtectedRoute>}></Route>
        {/* <ProtectedRoute path="/profile" component={Profile}/> */}
        <Route path="/blogg" exact element={<Blogg />} />
        <Route path="/terms" exact element={<Salesterms />} />
        <Route path="/privacy" exact element={<Privacy />} />
        <Route path="/prices" exact element={<Prices />} />
        <Route path="/templates" exact element={<Templates />} />
        <Route path="/login" exact element={<Login />} /> 
        <Route path="/nytt-passord" element={<ResetPasswordForm />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/faq" exact element={<FAQ />} />
        <Route path="/contact" exact element={<Contact />} />
        {/* <Route path="/createCVMal" exact element={<CreateCVTemplate />} /> */}
        <Route path="/createCVMal" element={<AdminRoute><CreateCVTemplate /></AdminRoute>} />
        <Route path="/agreements" element={<AdminRoute><Agreements /></AdminRoute>} />
        <Route path="/ctas" element={<AdminRoute><CTAS /></AdminRoute>} />
        <Route path="*" exact element={<Notfound />} />
      </Routes>
    </div>
  );
}



export default App;
