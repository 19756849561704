import { useState } from "react";
import Input from "./Input";
import PrimaryButton from "./buttons/PrimaryButton";
import axios from "axios";
import "../css/ResetPassword.css";

export default function ResetPasswordForm() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState(""); // 'success' or 'error'

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!email) {
            setMessage("Vennligst fyll inn e-post");
            setMessageType("error");
            return;
        }

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/resetPassword`, {
            email: email
        })
        .then(response => {
            setMessage("Tilbakestillingslenke er sendt til din e-post");
            setMessageType("success");
            setEmail("");
        })
        .catch(error => {
            setMessage("Noe gikk galt. Prøv igjen senere");
            setMessageType("error");
        });
    };

    return (
        <div className="resetPassword">
            <form onSubmit={handleSubmit}>
                <h1>Tilbakestill passord</h1>
                
                {message && (
                    <div className={`message ${messageType}`}>
                        {message}
                    </div>
                )}
                
                <div className="input-container">
                    <Input
                        type="email"
                        placeholder="E-post"
                        value={email}
                        onChange={(value) => setEmail(value)}
                    />
                </div>
                
                <div className="button-container">
                    <PrimaryButton
                        type="submit"
                        name="Send tilbakestillingslenke"
                    />
                </div>
            </form>
        </div>
    );
} 