import React, { useState, useEffect } from 'react';
import { ReactComponent as Star } from '../img/StarFilled.svg';

const Stars = ({ totalStars = 5, onRatingSelected, starValue }) => {
  const [hovered, setHovered] = useState(0);
  const [selectedRating, setSelectedRating] = useState(0);
  const handleMouseEnter = (value) => {
    setHovered(value);
  }
  const handleMouseLeave = () => setHovered(0);
  const handleClick = (value) => {
    setSelectedRating(value);
    if (onRatingSelected) onRatingSelected(value);
  };
  useEffect(() => {
    if(starValue) {
      setSelectedRating(starValue)
    }
  }, [starValue]);

  return (
    <div className="stars" onMouseLeave={handleMouseLeave}>
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
     
          <Star key={starValue}
          className={`starIcon ${starValue <= (hovered || selectedRating) ? 'filled' : ''}`}
          onMouseEnter={() => handleMouseEnter(starValue)}
          onClick={() => handleClick(starValue)}></Star>
    
      )})}
    </div>
  );
};

export default Stars;
