const convertImageToImg = () => {
    const imageTags = document.querySelectorAll("image");


    imageTags.forEach((imageTag) => {
      // Create a new <img> element
      const imgTag = document.createElement("img");

      // Copy over all attributes from the <image> tag to the new <img> tag
      for (let attr of imageTag.attributes) {
        imgTag.setAttribute(attr.name, attr.value);
      }

      // Replace the <image> tag with the new <img> tag in the DOM
      imageTag.parentNode.replaceChild(imgTag, imageTag);
    });
  };

  export default convertImageToImg;