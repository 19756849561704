import "../css/CreateCV.css";
import Logo from "../components/Logo";
import { useState, useRef, useEffect, useCallback } from "react";
import DraggableContainers from "../components/DraggableContainers";
import DraggableContainers2 from "../components/DraggableContainers2";
import ShowDesign from "../components/ShowDesign";
import PopupModal from "../components/PopupModal";
import PopupPayModal from "../components/PopupPayModal";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../img/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../img/arrowRight.svg";
import { useLocalStorage } from '../hooks/useLocalStorage';

import { useLocation } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import Mainla from "./templates/Mainla";
import Toyko from "./templates/Toyko";
// import Cali from "./templates/Cali";
import axios from "axios";
import DownloadablePDF from "../components/DownloadPDF";
import { useAuth } from "../components/AuthContext";
import Stokkholm from "./templates/Stokkholm";
import Asu from "./templates/Asu";
import Cali from "./templates/Cali";
import PdfViewer2 from "./templates/pdfViewer2";

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export default function CVCreater() {
  const location = useLocation();
  const { selectedTemplate, setSelectedTemplate, isTransitioning } = useAuth();
  const { isAuthenticated, userInfo, paid, setPaid } = useAuth();
  const [showDesign, setShowDesign] = useState(false);
  const [useInnhold, setUseInnhold] = useState(true);
  const [popupModal, setPopupModal] = useState(false);
  const [popupPayModal, setPopupPayModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmationToken, setConfirmationToken] = useState(null);
  const [renderedTemplate, setRenderedTemplate] = useState(null);

  let profileDummy = "https://plus.unsplash.com/premium_photo-1690294614341-cf346ba0a637?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  // Get design settings from localStorage
  const getDesignSettings = () => {
    console.log(selectedTemplate, "rendering");
    return {
      design: JSON.parse(localStorage.getItem('colorFormData') || '{}'),
      fonts: JSON.parse(localStorage.getItem('fontFamily') || '{"fontFamily": "Inter"}'),
      size: JSON.parse(localStorage.getItem('fontSettings') || '{"size": 10}'),
      profilePicture: localStorage.getItem('profilePicture') || profileDummy
    };
  };

  const getCvData = () => {
    return {
      Personalia: JSON.parse(localStorage.getItem('personalia') || '{}'),
      Arbeidserfaring: JSON.parse(localStorage.getItem('arbeidserfaring'))?.Arbeidserfaring || [],
      Utdanning: JSON.parse(localStorage.getItem('utdanning'))?.Utdanning || [],
      Ferdigheter: JSON.parse(localStorage.getItem('ferdigheter'))?.Ferdigheter || [],
      Språk: JSON.parse(localStorage.getItem('språk'))?.Språk || [],
      Interesser: JSON.parse(localStorage.getItem('interesser'))?.Interesser || [],
      Referanser: JSON.parse(localStorage.getItem('referanser'))?.Referanser || [],
      Militærtjeneste: JSON.parse(localStorage.getItem('militærtjeneste'))?.Militærtjeneste || [],
      Kurs: JSON.parse(localStorage.getItem('kurs'))?.Kurs || [],
      Frivillighet: JSON.parse(localStorage.getItem('frivillighet'))?.Frivillighet || [],
      Portefølje: JSON.parse(localStorage.getItem('portefølje') || '{}'),
      'kort oppsummering': JSON.parse(localStorage.getItem('kort oppsummering'))?.['kort oppsummering'] || ''
    };
  };

  useEffect(() => {
    let token = window.location.search.split("=")[1];
    setConfirmationToken(token);

    if(token) {
      fetchConfirmationToken(token);
    }
  }, [confirmationToken]);

  const fetchConfirmationToken = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/fetch-confirmation`, 
        {token: token}, 
        {withCredentials: true}
      );
      if(response.status === 200) {
        setPaid(true);
      } 
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownload = () => {
    if (!isAuthenticated) {
      setPopupModal(true);
    }
    if (isAuthenticated && !paid) {
      setPopupPayModal(true);
    }
  };

  // Update the template rendering to use localStorage
  const renderTemplate = useCallback(() => {
    if (!selectedTemplate?.name) return null;

    const designSettings = getDesignSettings();
    const cvData = getCvData();
    
    let template;
    switch (selectedTemplate.name) {
        case "Toyko":
            template = (
                <Toyko
                    key={selectedTemplate.lastUpdated}
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
            break;
        case "Mainla":
            template = (
                <Mainla
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
            break;
        case "Cali":
            template = (
                <Cali
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
            break;
        case "Stokkholm":
            template = (
                <Stokkholm
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
            break;
        case "Asu":
            template = (
                <Asu
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
            break;
        default:
            console.log("Using default template");
            template = (
                <Toyko
                    cvData={cvData}
                    cvDesign={designSettings.design}
                    profilePicture={designSettings.profilePicture || profileDummy}
                    selectedCVMal={selectedTemplate}
                    cvFonts={designSettings.fonts.fontFamily}
                    cvSize={designSettings.size.fontSize}
                />
            );
    }

    // Force immediate update
    setRenderedTemplate(null);
    requestAnimationFrame(() => {
        setRenderedTemplate(template);
    });

    return template;
  }, [selectedTemplate]);

  // Add useEffect to handle template rendering when selectedTemplate changes
  useEffect(() => {
    if (selectedTemplate?.name) {
        renderTemplate();
    }
  }, [selectedTemplate, renderTemplate]);

  // Also modify the storage change effect to include selectedTemplate dependency
  useEffect(() => {
    const handleStorageChange = () => {
      if (selectedTemplate && selectedTemplate.name) {
        renderTemplate();
      }
    };

    window.addEventListener('localStorageChange', handleStorageChange);
    return () => window.removeEventListener('localStorageChange', handleStorageChange);
  }, [selectedTemplate]);

  return (
    <div className={`gridContainer ${isTransitioning ? "fade-in" : ""}`}>
      <div className={`updating ${isUpdating ? 'visible' : ''}`}>
        Oppdaterer...
      </div>
      <div className="aside">
        <div className="logo">
          <a href="/">
            {" "}
            <Logo />
          </a>
        </div>
        <div
          className="innhold"
          onClick={() => {
            setUseInnhold(true);
            setShowDesign(false);
            if (
              !document
                .querySelector(".builder")
                .classList.contains("fullscreen")
            ) {
              if (window.innerWidth > 660) {
                document.querySelector(".builder").classList.toggle("hide");
              }
            }
            // document.querySelector(".builder").style.display = "grid";
            // document.querySelector(".preview").style.display = "none";
          }}
        >
          Innhold
        </div>
        <div
          className="design"
          onClick={() => {
            setShowDesign(true);
            setUseInnhold(false);
          }}
        >
          Design
        </div>
        {isAuthenticated ? (
          <div className="profile">
            <Link to="/profile">Min Profil</Link>
          </div>
        ) : (
          <div className="loginMenu">
            <a href="/login">Logg inn</a>
            {/* <Link to="/login">Logg inn</Link> */}
          </div>
        )}{" "}

        <div className="previewMobile"         onClick={() => {
          const previewContainer = document.querySelector(".cvTemplatePreview");
          if (previewContainer) {
            previewContainer.classList.toggle("fullscreen");
          }
        }}>
          <div className="previewMenyButton">Forhåndsvis</div>
        </div>
        {/* <div
          className="previewMobile"
          onClick={() => {
            const previewContainer = document.querySelector(".preview");
            if (previewContainer) {
              previewContainer.classList.toggle("fullscreen");
            }
            if (
              !document
                .querySelector(".builder")
                .classList.contains("fullscreen")
            ) {
              document.querySelector(".builder").classList.toggle("hide");
            }
            // document.querySelector(".builder").classList.toggle("hide");
            // document.querySelector(".builder").style.display = "none";
            // document.querySelector(".preview").style.display = "grid";
          }}
        >
          {" "}
          Forhåndsvis{" "}
        </div> */}
        <button className="cta2" name="LAST NED NÅ" onClick={handleDownload}>
          Last ned nå
        </button>
      </div>
      <div className="builder">
        <div className="builderWrapper">
          {useInnhold ? (
            // <DraggableContainers></DraggableContainers>
            <DraggableContainers2></DraggableContainers2>
          ) : (
            <div></div>
          )}
          {showDesign ? (
      
            <ShowDesign
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              selectedCVMal={selectedTemplate}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize}
            ></ShowDesign>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="cvTemplatePreview">
        <div className="previewContainer">
          {renderedTemplate && (
            <PdfViewer2 
              template={renderedTemplate}
              profilePicture={getDesignSettings().profilePicture || profileDummy}
              cvDesign={getDesignSettings().design}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize} 
              selectedTemplate={selectedTemplate.name}

            />
          )}
        </div>

        {isAuthenticated ? (
          paid ? (
            <DownloadablePDF
              className="downloadablePDF"
              cvDesign={getDesignSettings().design}
              selectedTemplate={selectedTemplate.name}
              cvFonts={getDesignSettings().fonts.fontFamily}
              cvSize={getDesignSettings().size.fontSize} 
            >
              onClick={handleDownload}
            </DownloadablePDF>
          ) : (
            <button className="cta2" onClick={() => setPopupPayModal(true)}>
              Last ned CV
            </button>
          )
        ) : (
          <button className="cta2" onClick={() => setPopupModal(true)}>
            Last ned CV
          </button>
        )}
      </div>

      {popupModal && <PopupModal setPopupModal={setPopupModal} />}
      {popupPayModal && <PopupPayModal setPopupPayModal={setPopupPayModal} />}
    </div>
  );
}
