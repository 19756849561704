import CVTemplateSlider from "./TemplateSlider";
import FontDropdown from "./FontDropdown.js";
import FontSizeSlider from "./FontSizeSlider.js";
import ColorPicker from "./ColorPicker.js";
import CustomSlider from "./Slider.js";
import axios from "axios";
import { useState, useEffect } from "react";
import getCVMal from "./getCVMal.js";

export default function ShowDesign({selectedTemplate, setSelectedTemplate, cvFonts, cvSize}){
    const [cvTemplates, setCvTemplates] = useState([]);
    const [primaryColors, setPrimaryColors] = useState([]);
    const [secondaryColors, setSecondaryColors] = useState([]);
    const [tertiaryColors, setTertiaryColors] = useState([]);
    
    
    useEffect(() => {
        getCVMal({setCvTemplates});
        getColors();

    }, []);



    async function getColors(){
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cvmal/colors`, {idcvMal: selectedTemplate.idcvMal}, {
            withCredentials: true,
        });
        setPrimaryColors(response.data.primaryColors);
        setSecondaryColors(response.data.secondaryColors);
        setTertiaryColors(response.data.tertiaryColors);
    }

    

    return(
        // <div>
            <div className="design-container">
                {/* <div className="cv-template-slider"> */}
                    <CVTemplateSlider templates={cvTemplates} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}/>
                {/* </div> */}
                <div className="font-selector">
                    <FontDropdown templates={cvTemplates} cvFonts={cvFonts} />
                </div>
                <div className="font-size-slider">  
                    {/* <FontSizeSlider /> */}
                    <CustomSlider templates={cvTemplates} cvSize={cvSize} />

                </div>
                <div className="color-picker">
                    <ColorPicker primaryColors={primaryColors} secondaryColors={secondaryColors} tertiaryColors={tertiaryColors} />
                </div>
            </div>
        // </div>
    )
}   