import "../css/Privacy.css";

export default function Privacy() {
  return (
    <div>
      <div className="privacy-container">
        <h1>Personvernerklæring</h1>
        <p>Personvernerklæring for bruk av tjenester hos LageCV.</p>
        <p>
          Ved å benytte LageCV, samtykker du til behandlingen av dine
          personopplysninger som beskrevet i denne erklæringen.
        </p>

        <h2>Forord</h2>
        <p>
          LageCV, en tjeneste levert av <a href="https://exuma.no">Exuma AS</a>{" "}
          (Organisasjonsnummer: 930 704 369MVA), (heretter referert til som
          “vi”, “oss” eller “vår”), anerkjenner viktigheten av personvern for
          våre brukere. Vi er forpliktet til å behandle personopplysninger med
          respekt og i samsvar med General Data Protection Regulation (GDPR).
          Denne personvernerklæringen forklarer hvordan vi samler inn, bruker og
          beskytter personopplysninger gjennom vår nettside LageCV.
        </p>
        <p>
          Vi oppfordrer deg til å lese gjennom erklæringen nøye for å forstå
          våre praksiser rundt personopplysninger og hvordan vi behandler dem.
          Ved spørsmål, vennligst kontakt oss på
          <strong>
            <a href="mailto:support@lagecv.no"> support@lagecv.no</a>
          </strong>
          .
        </p>

        <h2>Innhenting av informasjon</h2>
        <p>
          LageCV samler inn visse personopplysninger for å kunne tilby en
          skreddersydd og effektiv tjeneste for opprettelse av profesjonelle
          CV-er og følgebrev. Vi gir nå nærmere informasjon om hva som samles
          inn, inkludert både obligatorisk og frivillig informasjon.
        </p>

        <h3>Registrering hos oss</h3>
        <p>
          Vi samler inn personopplysninger gjennom registreringsprosessen, bruk
          av våre tjenester, og gjennom automatisk innsamling via
          informasjonskapsler og andre sporingsteknologier.
        </p>

        <h4>Obligatorisk informasjon</h4>
        <ul>
          <li>
            <strong>Navn og e-postadresse</strong>: Dette er grunnleggende
            opplysninger som kreves for å opprette en konto på LageCV.
            Informasjonene innhentes direkte fra brukeren ved registrering,
            eller eventuelt gjennom profiler fra sosiale medier dersom brukeren
            velger å logge inn på denne måten.
          </li>
        </ul>

        <h4>Frivillig informasjon</h4>
        <p>
          Alle frivillige data bidrar til å utforme CV-er og følgebrev, men er
          ikke nødvendig for grunnleggende bruk av våre tjenester. Informasjon
          som registreres i CV-er og følgebrev vil ikke bli benyttet av LageCV
          til noe annet formål, med unntak av uthenting av anonymiserte data ved
          behov for feilsøking.
        </p>
        <ul>
          <li>
            <strong>Navn</strong>: Benyttes i CV-er og følgebrev, samt ved
            eventuell utsending av markedsføringsmateriale.
          </li>
          <li>
            <strong>Telefonnummer</strong>: Benyttes i CV-er som
            kontaktinformasjon for arbeidsgivere.
          </li>
          <li>
            <strong>Adresse</strong>: Benyttes utelukkende som informasjon i
            CV-er og følgebrev.
          </li>
          <li>
            <strong>Førerkort</strong>: Nyttig for visse jobbsøknader hvor
            førerkort er relevant.
          </li>
          <li>
            <strong>Personlig nettsted og LinkedIn-profil</strong>: Gir mulighet
            til å inkludere digitale referanser eller porteføljer i CV-en.
          </li>
          <li>
            <strong>Nasjonalitet</strong>: Benyttes utelukkende som informasjon
            i CV-er.
          </li>
          <li>
            <strong>Fødselsnummer</strong>: Kan benyttes for spesifikke
            jobbsøknader som krever det.
          </li>
          <li>
            <strong>Kjønn, sivilstatus og fødselsdato</strong>: Benyttes
            utelukkende som informasjon i CV-er.
          </li>
        </ul>

        <h3>Bruk av våre tjenester</h3>
        <p>
          Når brukere benytter våre tjenester, kan de frivillig registrere en
          rekke personopplysninger som inkluderes i CV-er og følgebrev. Denne
          informasjonen benyttes kun til dette formålet.
        </p>
        <ul>
          <li>
            <strong>Utdanning</strong>: Inkludert institusjon, studienivå,
            studieretning og lignende.
          </li>
          <li>
            <strong>Arbeidserfaring</strong>: Jobbtitler, arbeidsgivere,
            arbeidsperioder og beskrivelser av arbeidsoppgaver og
            ansvarsområder.
          </li>
          <li>
            <strong>Kurs og sertifiseringer</strong>: Kursnavn, institusjoner og
            datoer for oppnåelse.
          </li>
          <li>
            <strong>Språkferdigheter</strong>: Språk og kompetansenivåer.
          </li>
          <li>
            <strong>Tekniske og andre ferdigheter</strong>: Relevante
            ferdigheter som kan registreres til CV-en.
          </li>
          <li>
            <strong>Hobbyer og interesser</strong>: Personlige interesser som
            kan være relevante for spesifikke jobber.
          </li>
          <li>
            <strong>Militærtjeneste og frivillige verv</strong>: Inkludert
            tjenesteperioder og roller i frivillige organisasjoner.
          </li>
        </ul>

        <h3>Deling av CV</h3>
        <p>
          For å effektivisere søknadsprosessen, tilbyr LageCV en funksjon for
          deling av din CV med potensielle arbeidsgivere. Når du velger å dele
          din CV gjennom vår tjeneste, vil den bli tilgjengelig via en unikt
          generert og randomisert URL. Denne tilnærmingen sikrer at URL-en ikke
          kan forutses eller gjettes av uautoriserte parter.
        </p>
        <p>
          Det er ditt ansvar å administrere tilgangen til din CV ved å dele
          URL-en forsiktig og kun med betrodde mottakere. Du kan til enhver tid
          oppheve delingen av din CV.
        </p>

        <h2>Formål med innsamling av data</h2>
        <p>Innsamlede data brukes til følgende formål:</p>
        <ul>
          <li>Registrere komplette CV-er og følgebrev.</li>
          <li>Muliggjøre nedlastning og deling av CV-er og følgebrev.</li>
          <li>Utsending av markedsføringsmateriale med brukerens samtykke.</li>
          <li>
            Forbedring og optimalisering av våre tjenester og annonseløsninger.
          </li>
        </ul>

        <h2>Deling med tredjeparter</h2>
        <p>
          Når vi deler personopplysninger med tredjeparter, er det med mål om å
          forbedre og tilpasse tjenestene vi tilbyr deg. Her er en detaljert
          oversikt over tredjepartene vi samarbeider med, hva som deles og
          formålet bak delingen.
        </p>

        <ul>
          <li>
            <strong>Mailchimp</strong>Dette deles: Navn, e-postadresse og
            eventuelt telefonnummer. Formål: Mailchimp benyttes for å sende ut
            nyhetsbrev og annen markedsføringskommunikasjon til brukere som har
            gitt sitt samtykke.
          </li>
          <li>
            <strong>Stripe</strong>Dette deles: Navn, e-postadresse og
            betalingsinformasjon. Formål: Stripe håndterer alle
            betalingstransaksjoner for LageCV.
          </li>
          <li>
            <strong>Google Analytics</strong>Dette deles: Anonymiserte data om
            brukeratferd. Formål: Google Analytics lar oss forstå hvordan våre
            brukere interagerer med nettsiden og tjenestene.
          </li>
          <li>
            <strong>Google Ads, Microsoft Ads og Facebook Ads</strong>Dette
            deles: Informasjon om brukerens interaksjoner på nettsiden. Formål:
            Målrettet markedsføring basert på brukerens tidligere aktiviteter og
            interesser.
          </li>
        </ul>

        <h2>Informasjonskapsler og markedsføring</h2>
        <p>
          Informasjonskapsler brukes for å forbedre nettstedets funksjonalitet,
          analysere brukertrender og tilby målrettet reklame.
        </p>

        <h2>Lagring og sikkerhet</h2>
        <p>
          Personopplysninger lagres i krypterte, passordbeskyttede databaser på
          egne servere for å forhindre uautorisert tilgang. Vi forplikter oss
          til å beskytte dine personopplysninger fra tap, misbruk, uautorisert
          tilgang, endring og ødeleggelse.
        </p>
        <p>
          LageCV tar datasikkerhet alvorlig og implementerer avanserte tekniske
          og organisatoriske sikkerhetstiltak for å beskytte dine
          personopplysninger. Til tross for strenge sikkerhetsprotokoller, er
          det viktig å være oppmerksom på at ingen sikkerhetssystemer er
          fullstendig ufeilbarlige.
        </p>
        <p>
          I det usannsynlige tilfellet av et datainnbrudd vil LageCV handle
          raskt for å identifisere årsaken, begrense skadene og informere de
          berørte partene så raskt som mulig.
        </p>

        <h2>Dine rettigheter</h2>
        <p>
          Ved å bruke våre tjenester, bekrefter du at du har lest og forstått
          denne personvernerklæringen og samtykker til behandlingen av dine
          personopplysninger.
        </p>
        <ul>
          <li>
            Be om komplett innsikt i personopplysningene vi har lagret om deg.
          </li>
          <li>Be om retting eller sletting av personopplysninger.</li>
          <li>Trekke tilbake samtykke til bruk av dine personopplysninger.</li>
        </ul>
        <p>
          For å utøve disse rettighetene, kontakt oss via{" "}
          <strong>
            <a href="mailto:support@lagecv.no">support@lagecv.no</a>
          </strong>
          .
        </p>

        <h2>Endringer i personvernerklæringen</h2>
        <p>
          Vi forbeholder oss retten til å gjøre endringer i denne
          personvernerklæringen. Den oppdaterte versjonen vil være tilgjengelig
          på vår nettside.
        </p>
        <p>
          <strong>Dato for siste oppdatering: 17.10.2024.</strong>
        </p>
        <div></div>
      </div>
    </div>
  );
}
